import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Divider, SvgIcon, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { doc, DocumentSnapshot, getDoc } from 'firebase/firestore'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import AlertDialog from '../../components/AlertDialog/AlertDialog'
import { ReactComponent as PublishIcon } from '../../icons/Publish.svg'
import { IAppContext } from "../../interfaces/IAppContext"
import { IGuidedPageLayout } from '../../interfaces/IGuidedPageLayout'
import { db, FirebaseContext, tagBrand } from '../../services/firebase'
import { getURL } from '../../utils/configs'
import { translations } from '../../utils/Translations'

export default function GuidedPageLayout({ account, pageTitle, children, isBrandHasMinReqToPublish }: IGuidedPageLayout) {
    const destinationRef = useRef<null | string>(null)
    const [returnUrl, setReturnUrl] = useState<string | null>("")
    const [skipUrl, setSkipUrl] = useState<boolean>(false) // flag to skip sending the return_url
    const [publishError, setPublishError] = useState<string>("")
    const brands = useSelector((state: any) => state.brands.brands)
    const [loading, setLoading] = useState(false)
    const [brand, setBrand] = useState<any>()
    const [dialogProps, setDialogProps] = useState<any | null>({})
    const { t } = useTranslation()
    const { id } = useParams<string>()
    const { ui } = useContext<IAppContext>(FirebaseContext)
    const uiState = useSelector((state: any) => state.uiState)
    const [urlParams, setUrlParams] = useSearchParams()
    const accountId = useRef<string | null>(null)
    const brandId = useRef<string | null>(null)

    accountId.current = urlParams.get('accountId')
    brandId.current = urlParams.get('brandId')

    function showPublishSkippedDialog() {
        setDialogProps({
            title: t(translations.NOT_PUBLISHED_ALERT, { destination: destinationRef.current }),
            content: t(translations.NOT_PUBLISHED_DESC, { brandName: brand.name, destination: destinationRef.current }),
            primaryButtonProps: {
                label: t(translations.NOT_PUBLISHED_BTN, { destination: destinationRef.current }),
                onClick: () => returnToDestination(true)
            },
            secondaryButtonProps: {
                label: t(translations.CANCEL),
                onClick: () => setDialogProps(null)
            },
            handleClose: () => setDialogProps(null),
            open: true
        })
    }

    function returnToDestinationHandler() {
        if (returnUrl) {
            const published: boolean = uiState.publishedOn > 0
            const updated: boolean = uiState.updatedOn > 0

            if (updated && !published) {
                showPublishSkippedDialog()
            } else if (!published && !updated) {
                // never published nor updated, let's see if there is a usable Tag to send when
                // returning to the destination
                getDoc(doc(db, `/accounts/${account}/brands/${id}/tags/${destinationRef.current}`))
                    .then((tagSnap: DocumentSnapshot) => {
                        if (tagSnap.exists()) {
                            // yes, there is a usable tag
                            returnToDestination()
                        } else {
                            // no, there is no usable tag
                            showPublishSkippedDialog()
                        }
                    })
            } else {
                returnToDestination()
            }
        }
    }

    function returnToDestination(forceSkip: boolean = false) {
        if (returnUrl) {
            if (skipUrl || forceSkip) {
                window.location.href = `${returnUrl}`
            } else {
                const url = getURL()
                window.location.href = `${returnUrl}?publishlink=${`${url}/${account}/${brand.id}/${destinationRef.current}.json`}`
            }
        }
    }

    function handlePublish() {
        if (isBrandHasMinReqToPublish) {
            if (brandId.current && brandId.current !== id) {
                setDialogProps({
                    title: t(translations.OVERWRITE_WARNING_TITLE, { destination: destinationRef.current }),
                    content: t(translations.OVERWRITE_WARNING_DESCRIPTION, { destination: destinationRef.current }),
                    handleClose: () => setDialogProps(null),
                    primaryButtonProps: {
                        label: t(translations.OVERWRITE_ANYWAYS, { destination: destinationRef.current }),
                        onClick: () => {
                            setSkipUrl(false)
                            publish()
                            setDialogProps(null)
                        }
                    },
                    secondaryButtonProps: {
                        label: t(translations.CANCEL),
                        onClick: () => setSkipUrl(true)
                    }
                })
            } else {
                publish()
            }
        } else {
            setDialogProps({
                secondaryButtonProps: { label: t(translations.OK) },
                handleClose: () => { setDialogProps(null) },
                title: t(translations.NO_ASSET_TITLE, { destination: destinationRef.current }), content: t(translations.NO_ASSET_DESC, { destination: destinationRef.current })
            })
        }
    }

    function publish() {
        setLoading(true)
        tagBrand({ brandId: brand.id, tagName: [destinationRef.current], accountId: account })
            .then(() => {
                ui.setPublishedOn(Date.now())
                setLoading(false)
            })
            .catch((error: any) => {
                setLoading(false)
                setPublishError(error.message)
                setDialogProps({
                    title: t("app-brand-return_publisherror-dlg-alert-title"),
                    content: t("app-brand-return_publisherror-dlg-alert-description") + t(publishError),
                    secondaryButtonProps: {
                        label: t(translations.OK),
                        onClick: () => setDialogProps(null)
                    },
                    handleClose: () => setDialogProps(null)
                })
            })
    }

    function setGuided() {
        const queryParams = new URLSearchParams(window.location.search)
        setReturnUrl(queryParams.get("return_url"))
        destinationRef.current = queryParams.get("destination")
    }

    useEffect(setGuided, [])
    useEffect(() => {
        const b = brands.filter((_b: any) => _b.id === id)
        setBrand(b[0])
    }, [brands, id])

    return <>
        <Box sx={{ pt: 2 }}>
            <Box sx={{ borderColor: 'divider', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography variant="h4" sx={{ mr: 2, pr: 2, borderRight: "2px solid grey", fontSize: "1.375rem" }} >
                        {pageTitle}
                    </Typography>
                    <Typography variant="subtitle1" >
                        {brand?.name}
                    </Typography>
                </Box>
                <Box>
                    <LoadingButton
                        sx={{ mr: 2 }}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        color='primary'
                        startIcon={<SvgIcon component={PublishIcon} inheritViewBox />}
                        onClick={handlePublish}
                    >
                        {t(translations.PUBLISH_TO_BRAND, { destination: destinationRef.current })}
                    </LoadingButton>
                    <Button variant='contained' color="secondary" onClick={returnToDestinationHandler}>
                        {t(translations.RETURN_TO_BRAND, { destination: destinationRef.current })}
                    </Button>
                </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 2, borderColor: "grey !important" }} />
            {children}
        </Box>
        {dialogProps &&
            <AlertDialog
                open={dialogProps?.title ? true : false}
                {...dialogProps}
            />
        }

    </>
}