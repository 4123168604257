import { Close, Photo } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"
import { ref, StorageError, uploadBytesResumable, UploadTaskSnapshot } from "firebase/storage"
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import { IFileUploader } from "../../interfaces/IFileUploader"
import { IState } from "../../interfaces/IState"
import { storage } from "../../services/firebase"
import { getSingleLogoFolderPath } from '../../utils/LogosUtils'
import { translations } from '../../utils/Translations'
import { getExtension } from '../../utils/utils'
import CircularProgress from "../CircularProgress/CircularProgress"
import ReplaceFileDialog from "./ReplaceFileDialog"

const FileUploader = (props: IFileUploader) => {
    const [progress, setProgress] = useState<null | number>(null)
    const uploadedRef = useRef(0)
    const fileExtensions = useRef<any>([])

    const [state, setState] = useState<IState>({
        confirmedFiles: [],
        acceptedFiles: [],
        rejectedFiles: [],
        currentFileIndex: 0,
        showDialog: false
    })

    function fileExtensionsValidator(file: File) {
        if (fileExtensions.current.includes(getExtension(file.name))) {
            return {
                message: "Double",
                code: "double"
            }
        }
        fileExtensions.current.push(getExtension(file.name))
        return null
    }

    function handleDrop(droppedFiles: File[]) {
        const extensions = props.currentExtensions
        const confirmedFiles = droppedFiles.filter((file) => extensions.includes(getExtension(file.name)))
        const acceptedFiles = droppedFiles.filter((file) => !extensions.includes(getExtension(file.name)))
        setState({ ...state, confirmedFiles, currentFileIndex: 0, showDialog: confirmedFiles.length > 0, acceptedFiles })
    }

    function handleAccept(file: File) {
        setState({ ...state, acceptedFiles: [...state.acceptedFiles, file], currentFileIndex: state.currentFileIndex + 1, showDialog: state.currentFileIndex !== state.confirmedFiles.length - 1 })
    }

    function handleReject(file: File) {
        setState({ ...state, rejectedFiles: [...state.rejectedFiles, file], currentFileIndex: state.currentFileIndex + 1, showDialog: state.currentFileIndex !== state.confirmedFiles.length - 1 })
    }

    async function uploadFileToFirebase(file: File) {
        const metadata = {}
        const filePath = `${getSingleLogoFolderPath(props.account, props.brandId, props.logoName)}/${getExtension(file.name)}.${getExtension(file.name)}`
        const storageRef = ref(storage, filePath)
        const uploadTask = uploadBytesResumable(storageRef, file, metadata)

        function onStateChange(snapshot: UploadTaskSnapshot) {
            const singleFileProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            const total = state.acceptedFiles.length * 100
            const completed = uploadedRef.current * 100 + singleFileProgress
            const currentProgress = completed * 100 / total
            setProgress(currentProgress)
            switch (snapshot.state) {
                case 'paused':
                    break
                case 'running':
                    break
            }
        }

        function onError(error: StorageError) {
            switch (error.code) {
                case 'storage/unauthorized':
                    break
                case 'storage/canceled':
                    break
                case 'storage/unknown':
                    break
            }
        }

        function onSuccess() {
            if (props.onFileUploaded) {
                props.onFileUploaded({
                    originalName: file.name,
                    name: `${getExtension(file.name)}.${getExtension(file.name)}`
                })
            }
            uploadedRef.current = uploadedRef.current + 1
        }

        uploadTask.on('state_changed', onStateChange, onError, onSuccess)

        return uploadTask
            .catch(onError)
    }

    function uploadFiles(droppedFiles: any) {
        setProgress(0)
        Promise
            .allSettled(droppedFiles.map((file: any) => uploadFileToFirebase(file)))
            .then(() => {
                props.onSuccess()
            })
    }

    useEffect(() => {
        if (state.showDialog === false && state.acceptedFiles.length > 0) {
            uploadFiles(state.acceptedFiles)
        }
    }, [state.showDialog, state.acceptedFiles])

    return <>
        {(progress || progress === 0) ?
            <CircularProgress progress={progress} sx={{ position: 'relative', display: 'block', margin: "auto" }} color="primary" />
            :
            state.showDialog ?
                <ReplaceFileDialog
                    open={state.showDialog}
                    handleAccept={() => handleAccept(state.confirmedFiles[state.currentFileIndex])}
                    handleReject={() => handleReject(state.confirmedFiles[state.currentFileIndex])}
                    fileName={state.confirmedFiles[state.currentFileIndex].name}
                />
                :
                <Box sx={{
                    "&:hover": {
                        cursor: "pointer",
                        //color: 'primary.main',
                        boxShadow: "2",
                        backgroundColor: 'action.hover',
                        borderStyle: "solid"
                    },
                    position: "relative",
                    border: "2px dashed #e9e5e3", flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", color: "#96938f"
                }}>
                    {props.showCloseBtn ? <IconButton
                        aria-label="close"
                        sx={{ position: "absolute", top: 0, right: 0 }} onClick={props.handleClose}><Close /></IconButton> : null}
                    <Dropzone
                        accept={{
                            'image/jpeg': [],
                            'image/jpg': [],
                            'image/png': [],
                            "image/gif": [],
                            "image/svg+xml": [],
                            "image/webp": []
                        }}
                        maxFiles={5}
                        onDrop={handleDrop} validator={fileExtensionsValidator} >
                        {({ getRootProps, getInputProps }) => (
                            <Box {...getRootProps({ style: { flexGrow: "1", textAlign: "center", width: "-webkit-fill-available", height: "-webkit-fill-available", display: "flex", justifyContent: "center", alignItems: "center" } })}>
                                <input {...getInputProps()} />
                                <div>
                                    <Photo />
                                    <p><b>{t(translations.DROP_FILES)}</b></p>
                                    <em>{t(translations.UPLOAD_REQUIRED)}</em>
                                </div>
                            </Box>
                        )}
                    </Dropzone>
                </Box >
        }
    </>
}

export default FileUploader