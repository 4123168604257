import { Container } from "@mui/material"
import { t } from "i18next"
import { Navigate, Route, Routes } from "react-router"
import { IGuidedRoutes } from "../../interfaces/IGuidedRoutes"
import fetchRoute, { ERoute } from "../../utils/routes"
import { translations } from "../../utils/Translations"
import BrandAssets from "../BrandAssets/BrandAssets"
import AddLogo from "../BrandAssets/Logos/AddLogo"
import SingleLogoListing from "../BrandAssets/Logos/SingleLogoListing"

const GuidedRoutes = ({ account, brands, user, setSnackbar, setAccount, accounts, logout }: IGuidedRoutes) => {
    return <>
        <Container sx={{ maxWidth: "unset !important", height: "-webkit-fill-available" }}>
            <Routes>
                <Route path={fetchRoute(ERoute.default.brandAssets)} element={<BrandAssets
                    account={account}
                    pageTitle={t(translations.MANAGE_BRAND_ASSETS)}
                    setSnackbar={setSnackbar}
                    setAccount={setAccount}
                    user={user}
                    accounts={accounts}
                    logout={logout}
                />} />
                <Route path={fetchRoute(ERoute.default.newLogo)} element={<AddLogo
                    account={account}
                    setSnackbar={setSnackbar}
                    setAccount={setAccount}
                    user={user}
                    accounts={accounts}
                    logout={logout}
                />} />
                <Route path={fetchRoute(ERoute.default.logo)} element={<SingleLogoListing
                    account={account}
                    setSnackbar={setSnackbar}
                    setAccount={setAccount}
                    user={user}
                    accounts={accounts}
                    logout={logout}
                />} />
                <Route path="*" element={<Navigate to={fetchRoute(ERoute.guided.brandAssets, { id: brands[0]?.id, tab: 0 })} replace />} />
            </Routes>
        </Container>
    </>
}

export default GuidedRoutes