import { Container } from "@mui/material"
import { useParams } from "react-router"
import PageHeader from "../../containers/Layouts/PageHeader"
import ResponsiveAppBar from "../../containers/ResponsiveAppBar/ResponsiveAppBar"
import { IAssetLayout } from "../../interfaces/IAssetLayout"
import { getFlowName } from '../../utils/utils'
import { AvatarMenu } from "../AvatarMenu/AvatarMenu"
import DefaultAssetLayout from './DefaultAssetLayout'
import GuidedAssetLayout from './GuidedAssetLayout'

const AssetLayout = ({ inputProps, addButtonProps, children, assetType, backFunction, account, user, accounts, setSnackbar, setAccount, logout }: IAssetLayout) => {
    const { id } = useParams<string>()

    switch (getFlowName()) {
        case "guided":
            return <GuidedAssetLayout assetType={assetType} inputProps={inputProps} addButtonProps={addButtonProps} backFunction={backFunction}>
                {children}
                <AvatarMenu
                    floating
                    accounts={accounts}
                    logout={logout}
                    setAccount={setAccount}
                    user={user}
                    setSnackbar={setSnackbar}
                />
            </GuidedAssetLayout>

        default:
            return <>
                <ResponsiveAppBar user={user} account={account} accounts={accounts} snackbar={setSnackbar} setAccount={setAccount} logout={logout} />
                <Container sx={{ flexGrow: '1', padding: "0 !important", height: "calc(100vh)", overflowY: "scroll", maxWidth: "unset !important", display: "flex", flexDirection: "column" }}>
                    <PageHeader account={account} id={id || ""} snackbar={setSnackbar} />
                    <Container sx={{ maxWidth: "unset !important", height: "-webkit-fill-available", display: "flex", flexDirection: "column" }}>
                        <DefaultAssetLayout children={children} inputProps={inputProps} addButtonProps={addButtonProps} backFunction={backFunction} />
                    </Container>
                </Container>
            </>
    }
}

export default AssetLayout