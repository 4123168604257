import { IDefaultAssetLayout } from "../../interfaces/IDefaultAssetLayout"
import AssetLayoutContent from './AssetLayoutContent'

const DefaultAssetLayout = (props: IDefaultAssetLayout) => {
    const { addButtonProps, backFunction } = props

    return <>
        <AssetLayoutContent {...props} showBackButton={true} backFunction={backFunction} addButtonProps={addButtonProps} />
    </>
}

export default DefaultAssetLayout