import { t } from "i18next"
import { Color } from "../models/Color"
import { Logo } from "../models/Logo"
import { translations } from "./Translations"

export function getExtension(filename: string): string {
    const a = filename.match(/\.([a-zA-Z0-9]+)$/)
    if (a) {
        const ext = a[1].toUpperCase()
        if (ext === "JPG") {
            return "JPEG"
        }
        return ext
    }
    return ""
}

export function updateElementInArray(arr: { object: string, url: string }[], name: string, url: string): { object: string, url: string }[] {
    for (const el of arr) {
        if (el.object === name) {
            el.url = url
            break
        }
    }
    return [...arr]
}
export const getFlowName = () => {
    return window.location.href.includes("guided") ? "guided" : "default"
}

export function formatBytes(bytes: number) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = 0
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
export function isThereAPrimaryColor(colors: Color[] | null) {
    if (colors) {
        for (const color of colors) {
            if (color.tag === 'Primary') return true
        }
    }
    return false
}
export function isThereAPrimaryLogo(logos: Logo[] | null) {
    if (logos) {
        for (const logo of logos) {
            if (logo.tag === 'Primary') return true
        }
    }
    return false
}
export function getDefaultNameOfNewLogo(logos: Logo[] | null | any[]): string {
    if (logos) {
        const logoPrefix = t(translations.LOGO_VARIATION, { n: "" }) as string
        // if there is  primary logo , return logo variation (x) based on the next avaiable number x
        if (isThereAPrimaryLogo(logos)) {
            return findNextVariationNumber(logos, logoPrefix)
        }
        // if there is no primary logo , return primary logo
        else {
            return t(translations.PRIMARY_LOGO) as string
        }
    }
    return ""
}

export function getDefaultNameOfNewColor(colors: Color[] | null | any[]): string {
    if (colors) {
        const colorPrefix = "Color Variation"
        // if there is  primary logo , return logo variation (x) based on the next avaiable number x
        if (isThereAPrimaryColor(colors)) {
            return findNextVariationNumber(colors, colorPrefix)
        }
        // if there is no primary logo , return primary logo
        else {
            return "Primary color"
        }
    }
    return ""
}
export function findNextVariationNumber(colors: any[], prefix: string): string {
    let nextNumber = 1
    const usedNumbers = colors.map((color) => {
        return color.name.split(prefix)[1] ? parseInt(color.name.split(prefix)[1].trim()) : null
    }).filter(n => n !== null)
    for (let i = 1; i <= colors.length + 1; i++) {
        if (!usedNumbers.includes(i)) {
            nextNumber = i
            break
        }
    }
    return `${prefix} ${nextNumber}`
}