import React from "react"
import { HexColorPicker } from "react-colorful"
import { IconButton, Popover } from "@mui/material"
import Colorize from "@mui/icons-material/Colorize"

export const ColorPicker = (props: { color: any, onChange: any }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return <>
        <div>
            <IconButton onClick={handleClick} aria-describedby={id}>
                <Colorize />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <HexColorPicker color={props.color} onChange={props.onChange} />
            </Popover>
        </div>
    </>
}

export default ColorPicker