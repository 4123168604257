import { Typography, Box, MenuItem, ListItem } from "@mui/material"
import { useTranslation, Trans } from "react-i18next"
import { useNavigate } from "react-router"
import Logo from "../../components/Logo/Logo"
import { TFirebaseUser } from "../../types/TFirebaseUser"
import fetchRoute, { ERoute } from "../../utils/routes"
import { translations } from "../../utils/Translations"
import { getFlowName } from "../../utils/utils"
const VerifyEmail = ({ user }: { user: TFirebaseUser }) => {
    const navigate = useNavigate()
    const flowName = getFlowName()
    const { t } = useTranslation()
    const signUpDifferentHandler = () => {
        navigate(fetchRoute(ERoute[flowName].signup))
    }
    const resendVerificationEmailHandler = () => {
        let url = window.location.href
        if (window.location.search !== "") url = `${url}&confirm_email=true`
        else url = `${url}?confirm_email=true`
        return user?.sendEmailVerification({ url: url })
    }
    return <>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", p: 2 }}>
            <Logo />
            <Box sx={{ display: "flex", flexDirection: "column", margin: "auto", maxWidth: "25rem" }}>
                <Typography variant="h4" color="primary" sx={{ pb: 2 }}>{t(translations.CHECK_INBOX_FOR_EMAIL)}</Typography>
                <Typography variant="subtitle1" sx={{ pb: 4 }}><Trans i18nKey={translations.CHECK_INBOX_FOR_EMAIL_DESC} values={{ email: user?.email }} /></Typography>
                <Typography variant="subtitle1" sx={{ pb: 1 }}><b>{t(translations.CANNOT_FIND_EMAIL)}</b>
                </Typography>
                <Typography variant="subtitle2">
                    <ListItem sx={{ pb: 1, display: 'list-item' }}>{t(translations.CHECK_SPAM)}</ListItem>
                    <ListItem sx={{
                        pb: 1, display: 'list-item', textDecoration: "underline", color: "primary.main", "&:hover": {
                            cursor: "pointer"
                        }
                    }} onClick={resendVerificationEmailHandler} >{t(translations.SEND_EMAIL_AGAIN)}</ListItem>
                    <ListItem sx={{
                        pb: 1, display: 'list-item', textDecoration: "underline", color: "primary.main", "&:hover": {
                            cursor: "pointer"
                        }
                    }} onClick={signUpDifferentHandler}>{t(translations.SIGN_UP_WITH_DIFFERENT_EMAIL)}</ListItem></Typography>
            </Box>
        </Box>
    </>
}
export default VerifyEmail