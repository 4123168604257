import { t } from "i18next"
import { Navigate, Route, Routes } from "react-router"
import fetchRoute, { ERoute } from "../../utils/routes"
import { translations } from "../../utils/Translations"
import Brand from "../Brand/Brand"
import { Tag } from "../Brand/Tag"
import BrandAssets from "../BrandAssets/BrandAssets"
import AddLogo from "../BrandAssets/Logos/AddLogo"
import SingleLogoListing from "../BrandAssets/Logos/SingleLogoListing"
import BrandSetup from "../Login/BrandSetup"
import { People } from "../People/People"
import { IAppRoutes } from "../../interfaces/IAppRoutes"

const AppRoutes = ({ account, user, brands, brandName, setBrandName, setSnackbar, accounts, setAccount, logout }: IAppRoutes) => {
    return <>
        <Routes>
            <Route path={fetchRoute(ERoute.default.brandSetup)} element={<BrandSetup brandName={brandName} setBrandName={setBrandName} />} />
            <Route path={fetchRoute(ERoute.default.brand)} element={<Brand
                account={account}
                user={user}
                snackbar={setSnackbar}
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                accounts={accounts}
                logout={logout}
            />} />
            <Route path={fetchRoute(ERoute.default.tag)} element={<Tag account={account} user={user} />} />
            <Route path={fetchRoute(ERoute.default.people)} element={<People
                account={account}
                user={user}
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                accounts={accounts}
                logout={logout}
            />} />
            <Route path={fetchRoute(ERoute.default.brandAssets)} element={<BrandAssets
                account={account}
                pageTitle={t(translations.BRAND_ASSETS)}
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                user={user}
                accounts={accounts}
                logout={logout}
            />} />
            <Route path={fetchRoute(ERoute.default.newLogo)} element={<AddLogo
                account={account}
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                user={user}
                accounts={accounts}
                logout={logout}
            />} />
            <Route path={fetchRoute(ERoute.default.logo)} element={<SingleLogoListing
                account={account}
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                user={user}
                accounts={accounts}
                logout={logout}
            />} />
            <Route path="*" element={<Navigate to={fetchRoute(ERoute.default.brandAssets, { id: brands[0]?.id, tab: 0 })} replace />} />
        </Routes>
    </>
}

export default AppRoutes