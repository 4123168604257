import GoogleIcon from '@mui/icons-material/Google'
import { Button } from "@mui/material"
import { loginWithGoogle } from "../../services/firebase"

const SignWithGoogleButton = (props: any) => {
    return <>
        <Button size="large" {...props} variant="contained" startIcon={<GoogleIcon />} onClick={loginWithGoogle} >
            <i className="fab fa-google"></i>
            {props.label}
        </Button>
    </>
}

export default SignWithGoogleButton