import { Navigate, Route, Routes, redirect } from "react-router"
import { IPublicRoutes } from "../../interfaces/IPublicGuidedRoutes"
import fetchRoute, { ERoute } from "../../utils/routes"
import BrandSetup from "../Login/BrandSetup"
import { Home } from "../Login/Home"
import SignIn from "../Login/SignIn"
import SignUp from "../Login/SignUp"
import VerifyEmail from "../Login/VerifyEmail"

const PublicGuidedRoutes = ({ brandName, setBrandName, user }: IPublicRoutes) => {
    return <>
        <Routes>
            <Route path={fetchRoute(ERoute.default.signin)} element={<SignIn />} />
            <Route path={fetchRoute(ERoute.default.signup)} element={<SignUp brandName={brandName} setBrandName={setBrandName} />} />
            <Route path={fetchRoute(ERoute.default.brandSetup)} element={<BrandSetup brandName={brandName} setBrandName={setBrandName} />} />
            <Route path={fetchRoute(ERoute.default.verifyEmail)} element={<VerifyEmail user={user} />} />
            <Route path={fetchRoute(ERoute.default.home)} element={<Home />} />
            <Route path="*" element={<Navigate to={fetchRoute(ERoute.guided.signin)} replace />} />
        </Routes>
    </>
}

export default PublicGuidedRoutes