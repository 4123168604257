import { ArrowBack } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, Input, Typography } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { IAssetLayoutContent } from '../../interfaces/IAssetLayoutContent'

function BackButton({ backFunction }: { backFunction?: Function }) {
    const navigate = useNavigate()

    function handleClick() {
        if (backFunction) return backFunction()
        return navigate(-1)
    }

    return (
        <IconButton onClick={handleClick}>
            <ArrowBack />
        </IconButton>
    )
}

const AssetLayoutContent = ({ showBackButton, inputProps, addButtonProps, children, backFunction }: IAssetLayoutContent) => {
    return <>
        <Box sx={{ flexGrow: 1, p: 2, display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {showBackButton && <BackButton backFunction={backFunction} />}
                    <FormControl variant="standard">
                        <Typography variant="h4">
                            <Input
                                fullWidth
                                sx={{
                                    "&::before": {
                                        borderBottomStyle: "dotted !important"
                                    }
                                }}
                                slotProps={{
                                    root: {
                                        style: {
                                            fontSize: "inherit",
                                            lineHeight: "inherit"
                                        }
                                    }
                                }}
                                id="name"
                                {...inputProps}
                            />
                        </Typography>
                    </FormControl>
                </Box>
                {addButtonProps &&
                    <div>
                        {addButtonProps.map((buttonProps) =>
                            <Button color="primary"
                                sx={{ ml: 2, height: "fit-content" }}
                                variant="contained" {...buttonProps}
                            />
                        )}
                    </div>
                }
            </Box>
            <Box sx={{ mt: 2, mb: 2, flexGrow: 1, display: "flex", flexDirection: "column" }}>
                {children}
            </Box>
        </Box >
    </>
}

export default AssetLayoutContent