import { Hexagon, Language } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { Button } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useSelector } from "react-redux"
import { useNavigate, useParams } from 'react-router'
import { AvatarMenu } from '../../components/AvatarMenu/AvatarMenu'
import Logo from "../../components/Logo/Logo"
import { TResponsiveAppBarProps } from '../../types/TResponsiveAppBarProps'
import fetchRoute, { ERoute } from '../../utils/routes'

function ResponsiveAppBar({ user, accounts, logout, setAccount, snackbar }: TResponsiveAppBarProps) {
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)
    const sidebarVisibility = useSelector<boolean>((state: any) => state.components?.sidebar?.visibility || true)

    const handleOpenNavMenu = (event: any) => { setAnchorElNav(event.currentTarget) }
    const handleOpenUserMenu = (event: any) => { setAnchorElUser(event.currentTarget) }
    const handleCloseUserMenu = () => { setAnchorElUser(null) }
    const { id } = useParams<string>()

    const navigate = useNavigate()

    function handleLogout() {
        logout()
    }

    return <>
        {sidebarVisibility && user &&
            <AppBar position="static" sx={{ right: 'auto', left: 0, flexDirection: "column", height: "100vh", width: "fit-content", padding: "2rem" }}>
                <Toolbar disableGutters sx={{ display: "flex", flexDirection: "column", height: "100vh", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <Box sx={{ display: { xs: 'flex', flexDirection: "column" } }}>
                        <Logo />
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex', flexDirection: "column", width: "calc(100% + 4rem)", marginLeft: "-2rem" } }}>
                            <Button variant="text" startIcon={<Hexagon />} sx={{ marginTop: 2, paddingLeft: "3rem", color: "white", justifyContent: "left" }} size="large" onClick={() => { navigate(fetchRoute(ERoute.default.brandAssets, { id: id, tab: 0 })) }}>Brand Assets</Button>
                            <Button variant="text" startIcon={<Language />} sx={{ marginTop: 2, paddingLeft: "3rem", color: "white", justifyContent: "left" }} size="large" onClick={() => { navigate(fetchRoute(ERoute.default.brand, { id: id })) }}>
                                Destinations</Button>
                        </Box>
                    </Box>
                    <AvatarMenu
                        accounts={accounts}
                        logout={logout}
                        setAccount={setAccount}
                        user={user}
                        setSnackbar={snackbar}
                    />
                </Toolbar>
            </AppBar >
        }
    </>
}

export default ResponsiveAppBar