import { Box, Input, useTheme } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import AssetLayout from "../../../components/AssetLayouts/AssetLayout"
import ColorPicker from "../../../components/ColorPicker/ColorPicker"
import MakePrimaryCheckbox from "../../../components/MakePrimaryCheckbox/MakePrimaryCheckbox"
import { IAppContext } from "../../../interfaces/IAppContext"
import { IColorAdd } from "../../../interfaces/IColorAdd"
import { Color } from "../../../models/Color"
import { Colors } from '../../../models/Colors'
import { FirebaseContext } from "../../../services/firebase"
import { addHash, addRGB, hexToRgb, isHexColor, rgbStringToObject, rgbToHex } from "../../../utils/colorUtils"
import fetchRoute, { ERoute } from "../../../utils/routes"
import { translations } from "../../../utils/Translations"
import { getFlowName } from '../../../utils/utils'

const AddColor = ({ account, close, defaultPrimary, defaultName, user, accounts, setSnackbar, setAccount, logout }: IColorAdd) => {
    const [colorName, setColorName] = useState<string>(defaultName)
    const [colorValue, setColorValue] = useState<string>("")
    const [makePrimary, setMakePrimaryColor] = useState<boolean>(defaultPrimary || false)
    const { api, ui } = useContext<IAppContext>(FirebaseContext)
    const { id } = useParams<string>()
    const unsubscribeGetBrand = useRef({})
    const navigate = useNavigate()
    const { t } = useTranslation()
    const theme = useTheme()

    if (!id) { throw new Error("no brand id") }

    function handleNameChange(e: any) {
        setColorName(e.currentTarget.value)
    }

    function handleValueChange(e: any, isHexField: boolean) {
        updateColorValue(isHexField ? addHash(e.currentTarget.value) : addRGB(e.currentTarget.value))
    }

    function updateColorValue(value: string) {
        setColorValue(value)
    }

    function getHexColor() {
        if (isHexColor(colorValue)) {
            return colorValue
        }
        const rgbString = rgbStringToObject(colorValue)
        return rgbString ? rgbToHex(rgbString) : ""
    }

    function getRGBColor() {
        return isHexColor(colorValue) ? hexToRgb(colorValue) : colorValue
    }

    function handleAddColor() {
        Colors
            .add({
                accountId: account,
                brandId: String(id),
                color: { name: colorName, value: colorValue }
            })
            .then((color: Color) => {
                // Track changes
                ui.setAssetsChangeTracker(Date.now())
                if (makePrimary) {
                    Colors.tag({
                        accountId: account,
                        brandId: String(id),
                        color: color,
                        as: 'Primary'
                    })
                }
                close()
            })
            .catch((error: any) => {
                if (error.message === "Invalid color payload") {
                    close()
                }
            })
    }

    function getBrandDocument() {
        if (account && id) {
            unsubscribeGetBrand.current = api.getBrand(account, id, () => {
                navigate(fetchRoute(ERoute[flowName].dashboard))
            })
        }
        return () => {
            if (unsubscribeGetBrand?.current && typeof unsubscribeGetBrand?.current === "function")
                unsubscribeGetBrand.current()
        }
    }

    const flowName = getFlowName()

    useEffect(getBrandDocument, [account])

    return <>
        <AssetLayout
            backFunction={handleAddColor}
            assetType='Colour'
            inputProps={{ onChange: handleNameChange, value: colorName, id: "name", placeholder: t(translations.UNTITLED_COLOUR) as string }}
            addButtonProps={[{
                children: <MakePrimaryCheckbox isPrimary={makePrimary} />,
                disableFocusRipple: makePrimary,
                disableRipple: makePrimary,
                disableElevation: makePrimary,
                sx: {
                    cursor: makePrimary ? 'default' : 'pointer',
                    "&:hover": { bgcolor: theme.palette.primary.main }
                },
                onClick: () => setMakePrimaryColor(true)
            }]}
            account={account}
            setSnackbar={setSnackbar}
            setAccount={setAccount}
            user={user}
            accounts={accounts}
            logout={logout}
        >
            <>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2, position: "relative" }}>
                    <ColorPicker onChange={updateColorValue} color={colorValue} />
                    <Input sx={{ mr: 2 }}
                        onChange={(e) => handleValueChange(e, true)}
                        value={getHexColor()}
                        id="name" placeholder={"#FFFFFF"}
                    />
                    <Input
                        onChange={(e) => handleValueChange(e, false)}
                        value={getRGBColor()}
                        id="name"
                        placeholder={"rgb(255,255,255)"}
                    />
                </Box>
                <Box sx={{ height: "100%", width: "100%", flexGrow: 1, backgroundColor: colorValue }} />
            </>
        </AssetLayout>
    </>
}

export default AddColor