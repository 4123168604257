import { Button } from "@mui/material"

const SignWithEmailButton = (props: any) => {
    return <>
        <Button size="large" color="secondary" {...props} variant='contained' onClick={props.onClick}>
            {props.label}
        </Button>
    </>
}

export default SignWithEmailButton