import AddBoxIcon from '@mui/icons-material/AddBox'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Stack } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { collection, DocumentSnapshot, onSnapshot, query, QuerySnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../services/firebase"
import PageLayout from "../Layouts/PageLayout"
import { TPeople } from '../../types/TPeople'

const userCols: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    { field: 'email', headerName: 'email', width: 300 },
    { field: 'role', headerName: 'role', width: 200 }
]

function inferRoleFromPermissions(permissions: { read: boolean, write: boolean, admin: boolean, owner: boolean }) {
    const { read, write, admin, owner } = permissions
    if (owner && read && write && admin) return 'owner'
    if (admin && read && write) return 'admin'
    if (read && !write && !admin && !owner) return 'viewer'
    if (write && read && !admin && !owner) return 'editor'
    if (write && !read && !admin && !owner) return 'blind-editor'
    if (admin && !read && !write && !owner) return 'blind-admin'
    return 'custom'
}

export function People({ account, user, accounts, setAccount, setSnackbar, logout }: TPeople) {
    const [users, setUsers] = useState<any[]>([])

    function listUsers() {
        function updateProps(querySnap: QuerySnapshot) {
            setUsers(querySnap.docs.map((qsdoc: DocumentSnapshot) => {
                const data = qsdoc.data() || {}
                return {
                    id: qsdoc.id,
                    email: data.email,
                    role: inferRoleFromPermissions(data.permissions)
                }
            }))
        }

        if (account) {
            return onSnapshot(
                query(
                    collection(db, `/accounts/${account}/users`)
                ),
                updateProps
            )
        }
    }

    useEffect(listUsers, [account])

    return <>
        <PageLayout
            pageTitle="People"
            account={account}
            setSnackbar={setSnackbar}
            setAccount={setAccount}
            user={user}
            accounts={accounts}
            logout={logout}
        >
            <div style={{ display: 'flex', height: '500px' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        pagination
                        pageSize={9}
                        rowsPerPageOptions={[9]}
                        experimentalFeatures={{ newEditingApi: true }}
                        aria-label="simple table"
                        rows={users}
                        columns={userCols}
                        checkboxSelection
                        disableSelectionOnClick
                    />
                </div>
            </div>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 0, marginTop: 1, width: '100%' } }}>
                <Stack direction="row" spacing={2}>
                    <Button disabled={true} variant="contained" startIcon={<AddBoxIcon />} >
                        <i className="fab fa-google"></i>invite
                    </Button>
                    <Button disabled={true} variant="contained" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Stack>
            </Box>
        </PageLayout>
    </>
}