import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useState } from "react"
import { ICreateTagDialog } from "../../interfaces/ICreateTagDialog"
import { tagBrand } from "../../services/firebase"

export function CreateTagDialog({ account, brandId, onClose, snackbar }: ICreateTagDialog) {
    const [tagName, setTagName] = useState<string>("")
    const [loading, setLoading] = useState(false)

    function handleTag(e: any) {
        setTagName(e.currentTarget.value)
    }

    function createTagHandler() {
        setLoading(true)
        tagBrand({ brandId: brandId, tagName: [tagName], accountId: account })
            .then(() => {
                setLoading(false)
                snackbar({ children: `Destination is created successfully`, severity: 'success' })
                onClose()
            })
            .catch((error: any) => {
                setLoading(false)
                snackbar({ children: `Destination failed to be created, try again later`, severity: 'error' })
                onClose()
            })
    }

    return <>
        <Dialog open onClose={onClose}>
            <Container sx={{ padding: 2 }}>
                <DialogTitle sx={{ pr: 0, pl: 0 }}>Add a new destination</DialogTitle>
                <DialogContent sx={{ pr: 0, pl: 0 }}>
                    <TextField
                        fullWidth
                        placeholder="Enter destination name"
                        value={tagName}
                        onChange={handleTag}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Destination"
                        type="text"
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions sx={{ pr: 0, pl: 0 }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <LoadingButton
                        disabled={!tagName || tagName.trim() === ""}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        color='primary'
                        startIcon={loading ? <SaveIcon /> : ""}
                        onClick={createTagHandler} autoFocus
                    >
                        add destination
                    </LoadingButton>
                </DialogActions>
            </Container >
        </Dialog >
    </>
}