import { Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const Logo = () => {
    const navigate = useNavigate()

    return <>
        <Box sx={{ display: { xs: 'flex', alignItems: "center" } }}>
            <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => {
                    navigate("/")
                }}
                sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none'
                }}
            >
                BrandRepo
            </Typography>
        </Box>
    </>
}

export default Logo
