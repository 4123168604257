import { Box, Input, useTheme } from "@mui/material"
import { t } from "i18next"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import AssetLayout from '../../../components/AssetLayouts/AssetLayout'
import ColorPicker from "../../../components/ColorPicker/ColorPicker"
import MakePrimaryCheckbox from "../../../components/MakePrimaryCheckbox/MakePrimaryCheckbox"
import { IAppContext } from "../../../interfaces/IAppContext"
import { IColorEdit } from "../../../interfaces/IColorEdit"
import { Colors } from '../../../models/Colors'
import { FirebaseContext } from "../../../services/firebase"
import { addHash, addRGB, hexToRgb, isHexColor, rgbStringToObject, rgbToHex } from "../../../utils/colorUtils"
import { translations } from "../../../utils/Translations"

const ColorEdit = ({ account, color, close, user, accounts, setSnackbar, setAccount, logout }: IColorEdit) => {
    const [colorName, setColorName] = useState<string>("")
    const [colorValue, setColorValue] = useState<string>("")
    const [colorHasChanged, setColorHasChanged] = useState<boolean>(false)
    const [markAsPrimary, setMarkAsPrimary] = useState<boolean>(false)
    const [isPrimary, setIsPrimary] = useState<boolean>(false)
    const { id } = useParams<string>()
    const { ui } = useContext<IAppContext>(FirebaseContext)
    const theme = useTheme()

    if (!id) { throw new Error("no brand id") }

    function handleNameChange(e: any) {
        setColorName(e.currentTarget.value)
        setColorHasChanged(true)
    }

    function handleValueChange(e: any, isHexField: boolean) {
        updateColorValue(isHexField ? addHash(e.currentTarget.value) : addRGB(e.currentTarget.value))
    }

    function updateColorValue(value: string) {
        setColorValue(value)
        setColorHasChanged(true)
    }

    function getHexColor() {
        if (isHexColor(colorValue)) {
            return colorValue
        }
        const rgbString = rgbStringToObject(colorValue)
        return rgbString ? rgbToHex(rgbString) : ""
    }

    function getRGBColor() {
        return isHexColor(colorValue) ? hexToRgb(colorValue) : color.value
    }

    function saveColorThenMakePrimaryThenClose() {
        saveColorThenMakePrimary()
            .then(() => {
                // Track changes
                ui.setAssetsChangeTracker(Date.now())
                close()
            })
    }

    async function saveColorThenMakePrimary() {
        if (color.id && id) {
            color.name = colorName
            color.value = colorValue
            if (markAsPrimary) {
                Colors.tag({
                    accountId: account,
                    brandId: id,
                    color: color,
                    as: 'Primary'
                })
            }
            if (colorHasChanged) {
                color.save()
            }
        }
    }

    function getColor() {
        if (color) {
            setColorName(color.name)
            setColorValue(color.value)
            setIsPrimary(color.tag === 'Primary')
        }
    }

    function setMarkAsPrimaryAndCloseDialog() {
        setMarkAsPrimary(true)
    }

    useEffect(getColor, [color])

    return <>
        <AssetLayout
            backFunction={saveColorThenMakePrimaryThenClose}
            assetType='Colour'
            inputProps={{ onChange: handleNameChange, value: colorName, id: "name", placeholder: t(translations.UNTITLED_COLOUR) as string }}
            addButtonProps={[{
                children: <MakePrimaryCheckbox isPrimary={isPrimary || markAsPrimary} />,
                disableFocusRipple: isPrimary || markAsPrimary,
                disableRipple: isPrimary || markAsPrimary,
                disableElevation: isPrimary || markAsPrimary,
                sx: {
                    cursor: (isPrimary || markAsPrimary) ? 'default' : 'pointer',
                    "&:hover": { bgcolor: theme.palette.primary.main }
                },
                onClick: () => {
                    if (!isPrimary && !markAsPrimary) {
                        setMarkAsPrimaryAndCloseDialog()
                    }
                }
            }]}
            account={account}
            setSnackbar={setSnackbar}
            setAccount={setAccount}
            user={user}
            accounts={accounts}
            logout={logout}
        >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2, position: "relative" }}>
                <ColorPicker onChange={updateColorValue} color={colorValue} />
                <Input
                    sx={{ mr: 2 }}
                    onChange={(e) => handleValueChange(e, true)}
                    value={getHexColor()}
                    id="name" placeholder={t(translations.ENTER_HEX) as string}
                />
                <Input
                    onChange={(e) => handleValueChange(e, false)}
                    value={getRGBColor()}
                    id="name"
                    placeholder={t(translations.ENTER_RGB) as string}
                />
            </Box>
            <Box sx={{ height: "100%", width: "100%", flexGrow: 1, backgroundColor: colorValue }} />
        </AssetLayout>
    </>
}

export default ColorEdit