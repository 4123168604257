import { CheckBox as CheckboxIcon } from "@mui/icons-material"
import { Card, Box, IconButton, Checkbox, CardHeader } from "@mui/material"

function CheckboxWithContrast(props: any) {
    // Calculate the luminance of the background color
    // to determine if the text should be light or dark
    const luminance = props
        .bgColor
        .replace(/^#/, "")
        .match(/.{2}/g)
        .reduce((a: string, b: string) => a + parseInt(b, 16), 0)


    const color = luminance > 382 ? "neutralLight" : "neutralDark"

    return <Checkbox color={color}
        {...props} />
}

export default CheckboxWithContrast
