import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

type PageLayoutProps = {
    pageTitle: string,
    children?: React.ReactNode,
    rightComponent?: React.ReactNode
}

export default function PageLayout({ pageTitle, children, rightComponent }: PageLayoutProps) {
    return <>
        <Box sx={{ padding: 2 }}>
            <Box sx={{ padding: 1, borderColor: 'divider', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h4" sx={{ fontSize: "1.375rem" }} gutterBottom>
                    {pageTitle}
                </Typography>
                {rightComponent && rightComponent}
            </Box>
            {children}
        </Box>
    </>
}