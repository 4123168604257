import { Box, Link, Typography } from "@mui/material"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import { ISignHeader } from "../../interfaces/ISignHeader"
import { fetchRoute } from '../../utils/routes'
import { translations } from "../../utils/Translations"

const SignHeader = ({ navigateTo, subtitle, navigateToLabel }: ISignHeader) => {
    const navigate = useNavigate()

    return <>
        <Typography variant="h4" sx={{ mb: 1 }}><b>{t(translations.SLOGAN)}</b></Typography>
        <Box sx={{ display: "flex" }}>
            <Typography variant="subtitle1">{subtitle} </Typography>
            &nbsp;
            <Link
                component="button"
                variant="body2"
                onClick={() => navigate(fetchRoute(navigateTo))}
            >
                <b> <Typography variant="subtitle1" > {navigateToLabel} </Typography></b>
            </Link>
        </Box>
    </>
}

export default SignHeader