import { collection, doc, DocumentSnapshot, onSnapshot, query, QuerySnapshot, updateDoc } from "firebase/firestore"
import { db } from "./services/firebase"
import { getURL } from "./utils/configs"

export function getBrands(account: any, successCallback: Function, failureCallback: any, dispatch: any) {
    function updateProps(querySnap: QuerySnapshot) {
        const rBrands: any[] = []
        querySnap.forEach((qsdoc: DocumentSnapshot) => {
            rBrands.push({
                id: qsdoc.ref.id,
                name: qsdoc.data()?.metadata?.name
            })
        })
        dispatch({ type: 'setBrands', payload: rBrands })
        successCallback(rBrands)
    }

    const unsubscribe = onSnapshot(query(collection(db, `/accounts/${account}/brands`)), updateProps, failureCallback)
    return unsubscribe
}

export function listTags(account: any, brandId: string, dispatch: any, successCallback?: Function) {
    function updateTags(querySnap: QuerySnapshot) {
        // TODO: make this dynamic to the env
        const url = getURL()
        const rtags = querySnap.docs.map((qsDoc: DocumentSnapshot) => {
            return {
                updatedOn: qsDoc.data()?.updatedOn,
                id: qsDoc.ref.id,
                url: `${url}/${account}/${brandId}/${qsDoc.ref.id}.json`
            }
        })
        dispatch({
            type: 'setTags',
            payload: rtags
        })
        if (successCallback) successCallback(rtags)
    }
    const unsubscribe = onSnapshot(query(collection(db, `/accounts/${account}/brands/${brandId}/tags`)), updateTags)
    return unsubscribe
}
/**
 * 
 * @param account the account id
 * @param id the brand id
 */
export function getBrand(account: any, id: string, dispatch: any) {
    function getBrandData(docSnap: DocumentSnapshot) {
        dispatch({ type: 'setBrand', payload: docSnap.data() })
    }
    const unsubscribe = onSnapshot(doc(db, `/accounts/${account}/brands/${id}`), getBrandData)
    return unsubscribe
}

export function updateBrand(account: any, id: string, updatedBrand: any, dispatch: any) {
    const docRef = doc(db, `/accounts/${account}/brands`, id)
    updateDoc(docRef, updatedBrand)
        .then(() => {
            dispatch({ type: 'setBrand', payload: updatedBrand })
        })
        .catch((e) => {
            console.log("error", e)
        })
}

export function getLogo(account: any, brandId: string, logoId: string, dispatch: any) {
    function getLogoData(docSnap: DocumentSnapshot) {
        dispatch({ type: 'setBrandSingleLogo', payload: { logoId: docSnap.data()?.id, logoName: docSnap.data()?.name } })
    }
    const unsubscribe = onSnapshot(doc(db, `/accounts/${account}/brands/${brandId}/assets/${logoId}`), getLogoData)
    return unsubscribe
}

export function updateSingleLogo(account: any, brandId: string, logoId: string, updatedLogo: any, dispatch: any) {
    const docRef = doc(db, `/accounts/${account}/brands/${brandId}/assets/${logoId}`)
    updateDoc(docRef, updatedLogo)
        .then(() => {
            //dispatch({ type: 'setBrand', payload: updatedBrand })
        })
        .catch((e) => {
            console.log("error", e)
        })
}

export function logout() {
    return {
        type: "logout"

    }
}
