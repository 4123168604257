import { Box, CardMedia, Typography } from '@mui/material'
import AssetCard from '../../../components/AssetCard/AssetCard'
import TextWithContrast from '../../../components/ComponentsWithContrast/TextWithContrast'
import { ILogoCard } from '../../../interfaces/ILogoCard'

const LogoCard = ({ logo, selected, isDarkMode, toggleSelected, selectionMode, menuActions, onClick, allowSelection }: ILogoCard) => {
    return <>
        <AssetCard
            onCardClick={onClick}
            toggleSelected={toggleSelected}
            menuActions={menuActions}
            bgColor={isDarkMode ? "#000000" : "#FFFFFF"}
            selected={selected}
            allowSelection={allowSelection}
            selectionMode={selectionMode}
            sx={{ backgroundColor: isDarkMode ? "#000000" : "#FFFFFF", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
            {logo.url ?
                <CardMedia
                    sx={{
                        objectFit: "scale-down",
                        maxHeight: "calc(100% - 7.5rem)"
                    }}
                    component="img"
                    title={logo.nameOverride || logo.name}
                    image={logo.url}
                    onClick={onClick}
                />
                :
                <Box onClick={onClick} sx={{ width: "-webkit-fill-available", height: "3rem" }} />
            }
            <Typography sx={{ mt: 0, pl: 2, visibility: logo.tag ? "visible" : "hidden" }} >
                <TextWithContrast bgColor={isDarkMode ? "#000000" : "#FFFFFF"} >
                    {logo.tag ? logo.tag : "tagplaceholder"}
                </TextWithContrast>
            </Typography>
            <Typography sx={{ mt: 0, pl: 2, pb: 2 }} onClick={onClick}>
                <TextWithContrast bgColor={isDarkMode ? "#000000" : "#FFFFFF"} >
                    <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "flex-end" }}>
                        <b>{logo.nameOverride || logo.name}</b>     &nbsp;
                        <Typography variant="subtitle2">{logo.additionalFooterText}</Typography>
                    </Box>
                </TextWithContrast>
            </Typography>
        </AssetCard>
    </>
}

export default LogoCard