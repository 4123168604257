import { Box, Button, Container, Dialog, Grid } from '@mui/material'
import { Account } from '../../types/Account'
import '../App/App.css'

type IAccountSelection = {
    account: string | null
    accounts: Account[] | null
    setAccount: CallableFunction
}

// TODO: this is very ghetto and should be designed when we implement multi-account support

export function AccountSelection({ account, accounts, setAccount }: IAccountSelection) {
    function setAccountAndClode(a: Account) {
        setAccount(a.id)
    }

    return <>
        <Dialog open={account ? true : false}>
            <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
                <Grid container>
                    <Box minWidth={500} sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 1, width: '100%' } }}>
                        <Grid item>
                            Please select an Account
                        </Grid>
                        <Grid item>
                            {accounts &&
                                <>
                                    {accounts.map((a: Account) =>
                                        <Button variant="outlined" onClick={() => setAccountAndClode(a)}>{a.id}</Button>
                                    )}
                                </>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Container>
        </Dialog>
    </>
}

export default AccountSelection
