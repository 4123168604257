export enum translations {
    ADD_COLOUR = "app-brandassets_new-btn_colour",
    ADD_LOGO = "app-brandassets_new-btn_logo",
    ADD_LOGO_VARIATION = "app-brandassets_logosListing-newTile_title",
    ADD_PRIMARY_COLOUR = "app-brandassets_coloursListing-primaryTile_title",
    ADD_PRIMARY_LOGO = "app-brandassets_logosListing-primaryTile_title",
    BRAND_ASSETS = "app-brandassets-page_title",
    CANCEL = "app-general-cancel",
    COLOUR = "app-general-colour",
    COLOUR_PRIMARY_TILE_DESC = "app-brandassets_coloursListing-primaryTile_description",
    COLOUR_TILE_DESC = "app-brandassets_coloursListing-newTile_description",
    COMFIRM_COLOR_PRIMARY = "app-general_primarycolor-confirm",
    COMFIRM_DELETE = "app-general_deletion-confirm",
    COMFIRM_LOGO_PRIMARY = "app-general_primarylogo-confirm",
    CREATE_ACCOUNT = "app-auth-signinheader-subtitle",
    DARK_MODE = "app-logoslisting-darkmode",
    DELETE = "app-general-delete",
    DONE = "app-general-done",
    DROP_FILES = "app-brandassets_logos_fileUploader_title",
    DUPLICATE = "app-general-duplicate",
    EMAIL = "app-auth-form-email-lbl",
    EMAIL_IN_USE = "app-validation-email_inuse",
    EMAIL_MAYBE_RESET = "app-validation-email_maybe_reset",
    EMAIL_NOT_FORMATTED = "app-validation-email_format",
    EMAIL_REQUIRED = "app-validation-email_empty",
    EMAIL_NOT_VERIFIED = "app-validation-email_not_verified",
    EMAIL_WRONG = "app-validation-email_wrong",
    ENTER_BRAND_NAME = "app-auth-signupheader-brand-lbl",
    ENTER_DESTINATION = "app-brand-publishDlg_input_lbl",
    ENTER_HEX = "app-brandassets_newColour-hexinput-pl",
    ENTER_RGB = "app-brandassets_newColour-rgbinput-pl",
    FAILURE_PUBLISH_TO_ALL = "brand-published-to-all-failure",
    FORGOT_PASSWORD_QUESTION = "app-auth-forgot-password-question",
    LOGO_PRIMARY_TILE_DESC = "app-brandassets_logosListing-primaryTile_description",
    LOGO_TILE_DESC = "app-brandassets_logosListing-newTile_description",
    LOGO_VARIATION = "app-brandassets_newLogo-input-default-variation",
    MAKE_PRIMARY = "app-general-makePrimary",
    MANAGE_BRAND_ASSETS = "app-brandassets-guided-page_title",
    NEW_ASSET = "app-brandassets_new-btn_lbl",
    NOT_PUBLISHED_ALERT = "app-brand-return_nopublish-dlg-alert-title",
    NOT_PUBLISHED_BTN = "app-brand-return_nopublish-dlg-alert-btn-primary-lbl",
    NOT_PUBLISHED_DESC = "app-brand-return_nopublish-dlg-alert-description",
    NO_ACCOUNT = "app-auth-signupheader-subtitle",
    NO_ASSET_BEFORE_DESCRIPTION = "app-brand-return_nopublishbefore-dlg-alert-description",
    NO_ASSET_BEFORE_TITLE = "app-brand-return_nopublishbefore-dlg-alert-title",
    NO_ASSET_DESC = "app-brand-return_noasset-dlg-alert-description",
    NO_ASSET_TITLE = "app-brand-return_noasset-dlg-alert-title",
    OK = "app-general-ok",
    OTHER_COLOUR = "app-brandassets_coloursListing-newTile_title",
    OVERWRITE_ANYWAYS = "app-brand-publish-overwrite-anyways",
    OVERWRITE_WARNING_DESCRIPTION = "app-brand-publish-overwrite-description",
    OVERWRITE_WARNING_TITLE = "app-brand-publish-overwrite-title",
    PASSWORD = "app-auth-form-pwd-lbl",
    PRIMARY = "app-general-primary",
    PRIMARY_LOGO = "app-brandassets_newLogo-input-default-primary",
    PUBLISH = "app-general-publish",
    PUBLISH_BRAND = "app-brand-publishDlg_title",
    PUBLISH_TO_ALL = "publish_to_all_btn",
    PUBLISH_TO_BRAND = "app-brand-publishTo-btn_lbl",
    RETURN_TO_BRAND = "app-brand-returnTo-btn_lbl",
    RESET_PASSWORD = "app-auth-resetpwdheader-lbl",
    SIGN_IN = "app-auth-signinheader-lbl",
    SIGN_IN_DIFFERENTLY = "app-auth-signin-different-header-lbl",
    SIGN_IN_EMAIL = "app-auth-signin-email",
    SIGN_IN_GOOGLE = "app-auth-signin-google",
    SIGN_UP = "app-auth-signupheader-lbl",
    SIGN_UP_DIFFERENTLY = "app-auth-signup-different-header-lbl",
    SIGN_UP_EMAIL = "app-auth-signup-email",
    SIGN_UP_GOOGLE = "app-auth-signup-google",
    SLOGAN = "app-slogan",
    SPECIFIC = "publish_to_specific_btn",
    SUCCESS_PUBLISH_TO_ALL = "brand-published-to-all-success",
    UNTITLED_COLOUR = "app-brandassets_newColour-input-pl",
    UNTITLED_LOGO = "app-brandassets_newLogo-input-pl",
    UPLOAD_FILES = "app-brandassets_logos_images-uploadBtn-lbl",
    UPLOAD_REQUIRED = "app-brandassets_logos_fileUploader_description",
    XSELECTED = "app-brandassets-snack_selected",
    CHECK_INBOX_FOR_EMAIL = "check_inbox_for_email_title",
    CANNOT_FIND_EMAIL = "check_inbox_for_email_not_found",
    CHECK_SPAM = "check_inbox_for_email_check_spam",
    SEND_EMAIL_AGAIN = "check_inbox_for_email_check_send-again",
    SIGN_UP_WITH_DIFFERENT_EMAIL = "check_inbox_for_email_check_sign-up-with-different-email",
    CHECK_INBOX_FOR_EMAIL_DESC = "check_inbox_for_email_check_desc"
}