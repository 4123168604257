import { Box, CircularProgress } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddCard from '../../../components/AddCard/AddCard'
import AlertDialog from '../../../components/AlertDialog/AlertDialog'
import SelectionSnackbar from '../../../components/Snackbars/SelectionSnackbar'
import { IAppContext } from "../../../interfaces/IAppContext"
import { Color } from "../../../models/Color"
import { FirebaseContext } from '../../../services/firebase'
import { TColorsListingProps } from '../../../types/TColorsListingProps'
import { translations } from '../../../utils/Translations'
import { isThereAPrimaryColor } from '../../../utils/utils'
import ColorCard from './ColorCard'

const ColorsListing = ({ duplicateBrandColor, colorEdit, newColor, colors }: TColorsListingProps) => {
    const [openDeleteDlg, setOpenDeleteDlg] = useState<Color | null>(null)
    const [primaryBtnLoading, setPrimaryBtnLoading] = useState(false)
    const { t } = useTranslation()
    const { ui } = useContext<IAppContext>(FirebaseContext)
    const [selectedColors, setSelectedColors] = useState<Color[]>([])

    function handleBrandColorDelete(color: Color) {
        setOpenDeleteDlg(color)
    }

    function handleColorClickWhenSelectionMode(color: Color, isToggle: boolean) {
        if (selectedColors.length > 0 || isToggle) {
            if (selectedColors.filter(col => col.id === color.id).length > 0) {
                setSelectedColors(selectedColors.filter(col => col.id !== color.id))
            } else {
                setSelectedColors([...selectedColors, color])
            }
        }
        else {
            colorEdit(color)
        }
    }

    function deleteBrandColor(color: Color) {
        setPrimaryBtnLoading(true)
        color.delete()
            .then(() => {
                //  Track changes
                ui.setAssetsChangeTracker(Date.now())
                setOpenDeleteDlg(null)
                setPrimaryBtnLoading(false)
            })
    }

    function deleteSelectionsHandler() {
        selectedColors.forEach(color => {
            color.delete()
                .then(() => {
                    // Track changes
                    ui.setAssetsChangeTracker(Date.now())
                    setSelectedColors((updatedColors) => updatedColors.filter(i => i !== color))
                })
        })
    }

    return <>
        <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(25rem, 1fr))" gap={1}>
            {colors ?
                <>
                    {colors.map((color: Color) => {
                        return <>
                            <ColorCard
                                allowSelection={true}
                                toggleSelected={() => handleColorClickWhenSelectionMode(color, true)}
                                selectionMode={selectedColors.length > 0}
                                selected={selectedColors.filter((col: Color) => col.id === color.id).length > 0}
                                onCardClick={() => handleColorClickWhenSelectionMode(color, false)}
                                color={color}
                                duplicateBrandColor={duplicateBrandColor}
                                deleteBrandColor={handleBrandColorDelete}
                            />

                        </>
                    })
                    }
                    {isThereAPrimaryColor(colors) ?
                        <AddCard
                            title={t(translations.OTHER_COLOUR)}
                            description={t(translations.COLOUR_TILE_DESC)}
                            onClick={() => newColor(false)}
                        />
                        :
                        <AddCard
                            title={t(translations.ADD_PRIMARY_COLOUR)}
                            description={t(translations.COLOUR_PRIMARY_TILE_DESC)}
                            onClick={() => newColor(true)}
                        />
                    }
                </>
                :
                <CircularProgress sx={{ margin: "auto", display: "block", position: "absolute", left: "50vw", top: "50vh" }} color="primary" />
            }
            <SelectionSnackbar numberOfSelectedElements={selectedColors.length}
                actions={[{ label: t(translations.DELETE), onClick: deleteSelectionsHandler }]}
            />
            <AlertDialog
                title={t(translations.COMFIRM_DELETE, { data: `${t(translations.COLOUR)}?` })}
                primaryButtonProps={{ loading: primaryBtnLoading, label: "yes", onClick: () => openDeleteDlg && deleteBrandColor(openDeleteDlg) }}
                secondaryButtonProps={{
                    label: t(translations.CANCEL),
                    onClick: () => { setOpenDeleteDlg(null) }
                }} content={''}
                open={openDeleteDlg !== null}
                handleClose={() => { setOpenDeleteDlg(null) }}
            />
        </Box>
    </>
}

export default ColorsListing

