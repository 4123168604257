import { Box, Container, Grid, TextField, Typography } from "@mui/material"
import { IBrandSetup } from "../../interfaces/IBrandSetup"

const BrandSetup = ({ setBrandName, brandName }: IBrandSetup) => {
    return <>
        <Grid container sx={{ width: "100vw", zIndex: 22, backgroundColor: "red" }}>
            <Grid item xs={4} sx={{ p: 2 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>BrandSdk</Typography>
                <Container maxWidth="md" sx={{ paddingTop: 10, paddingBottom: 4 }}>
                    <Typography variant="h3" sx={{ mb: 1 }}><b>Give your brand a home</b></Typography>
                    <TextField
                        sx={{ width: "-webkit-fill-available", mt: 3 }}
                        id="brandName"
                        label="Enter brand name to get started"
                        variant="outlined"
                        onChange={(e) => { setBrandName(e.target.value) }}
                        value={brandName}
                    />
                </Container>
            </Grid>
            <Grid item xs={8}>
                <Box sx={{ backgroundColor: "#e3e0dc", height: "100%" }}></Box>
            </Grid>
        </Grid >
    </>
}

export default BrandSetup