import { Delete } from '@mui/icons-material'
import { Box, CardContent, Typography } from '@mui/material'
import React from 'react'
import AssetCard from '../../../components/AssetCard/AssetCard'
import TextWithContrast from "../../../components/ComponentsWithContrast/TextWithContrast"
import { IColorCard } from '../../../interfaces/IColorCard'

const ColorCard = ({ color, selected, selectionMode, toggleSelected, allowSelection, deleteBrandColor, onCardClick }: IColorCard) => {
    function handleDelete(event: React.MouseEvent) {
        event.stopPropagation()
        deleteBrandColor(color)
    }

    return <>
        <AssetCard sx={{ bgcolor: color.value }}
            onCardClick={onCardClick}
            selected={selected}
            toggleSelected={toggleSelected}
            menuActions={[{
                icon: < Delete titleAccess="delete" />,
                onClick: handleDelete
            }]}
            allowSelection={allowSelection}
            bgColor={color.value ? color.value : "#000000"}
            selectionMode={selectionMode}
        >
            <CardContent
                onClick={onCardClick}
                sx={{ pb: "1rem !important", pt: 0, bgcolor: color.value, flexGrow: "1", display: "flex", flexDirection: "column" }}
            >
                <Box sx={{ bgColor: color.value, height: "2rem", flexGrow: "1" }} />
                <Typography><TextWithContrast bgColor={color.value} >{color.tag}</TextWithContrast></Typography>
                <Typography><TextWithContrast bgColor={color.value} ><b>{color.name}</b></TextWithContrast></Typography>
            </CardContent>
        </AssetCard >
    </>
}

export default ColorCard