import { Box, Switch, Typography } from '@mui/material'
import { t } from 'i18next'
import { IDarkmodeSwitch } from '../../../interfaces/IDarkmodeSwitch'
import { translations } from '../../../utils/Translations'

const DarkmodeSwitch = ({ logo, isDarkMode }: IDarkmodeSwitch) => {
    async function handleSwitchOnChange(event: any) {
        const updatedValue = event.target.checked
        logo.darkmode = updatedValue
        return logo.save()
    }

    return <>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
            <Typography variant="subtitle2">{t(translations.DARK_MODE)}</Typography> <Switch checked={isDarkMode} onChange={handleSwitchOnChange} />
        </Box>
    </>
}

export default DarkmodeSwitch