import { Delete, Download } from '@mui/icons-material'
import { Box, CircularProgress } from '@mui/material'
import { collection, getDocs, query, QuerySnapshot } from 'firebase/firestore'
import { getDownloadURL, ref } from 'firebase/storage'
import JSZip from 'jszip'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import AddCard from "../../../components/AddCard/AddCard"
import AlertDialog from '../../../components/AlertDialog/AlertDialog'
import { IAppContext } from "../../../interfaces/IAppContext"
import { ILogosListing } from '../../../interfaces/ILogosListing'
import { Images } from '../../../models/Images'
import { Logos } from '../../../models/Logos'
import { db, FirebaseContext, storage } from '../../../services/firebase'
import fetchRoute, { ERoute } from '../../../utils/routes'
import { translations } from '../../../utils/Translations'
import { getFlowName, isThereAPrimaryLogo } from '../../../utils/utils'
import LogoCard from './LogoCard'

const LogosListing = ({ account, brandId, logos }: ILogosListing) => {
    const [images, setImages] = useState<any[]>([])
    const [openDeleteDlg, setOpenDeleteDlg] = useState<string | null>(null)
    const [primaryBtnLoading, setPrimaryBtnLoading] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { ui } = useContext<IAppContext>(FirebaseContext)
    const brands = useSelector((state: any) => state.brands.brands)
    const isDarkMode = useSelector((state: any) => state.uiState.logosListing.isDarkMode)

    function handleBrandLogoDelete(logoId: string) {
        setOpenDeleteDlg(logoId)
    }

    function deleteBrandLogo(logoId: string) {
        setPrimaryBtnLoading(true)
        Logos.delete({ accountId: account, brandId: brandId, logoId: logoId })
            .then(() => {
                // Track changes
                ui.setAssetsChangeTracker(Date.now())
                setOpenDeleteDlg(null)
                setPrimaryBtnLoading(false)
            })
    }

    function getImages() {
        if (account && brandId && logos) {
            const unsubs = Images.getOneImageForManyLogos({ accountId: account, brandId: brandId, logos: logos, dispatch: setImages })
            return () => unsubs.forEach(unsub => unsub())
        }
    }

    async function handleDownload(logoId: string) {
        const zip = new JSZip()
        const downloadedLogo = logos && logos.filter((logo: any) => logo.id === logoId)[0]
        if (downloadedLogo) {
            getDocs(query(collection(db, `/accounts/${account}/brands/${brandId}/assets/${logoId}/files`)))
                .then(async (qs: QuerySnapshot) => {
                    for (const doc of qs.docs) {
                        const data = doc.data()
                        await getDownloadURL(ref(storage, data.gsReference))
                            .then(async (url: string) => {
                                return fetch(url)
                            })
                            .then(async (response: Response) => {
                                return zip.file(data.name, await response.blob())
                            })
                    }
                })
                .then(async () => {
                    const zipBlob = await zip.generateAsync({ type: 'blob' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(zipBlob)
                    link.download = `${brands[0].name}_${downloadedLogo.name}.zip`
                    link.click()
                })
        }
    }

    function getUrl(logoName: string) {
        if (images) {
            for (const image of images) {
                const match = image.gsReference.match(`/${logoName}/(.*)$`)
                if (match) return image.downloadUrl
            }
        }
        return null
    }

    const flowName = getFlowName()

    useEffect(getImages, [account, brandId, logos])

    return <>
        <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(25rem, 1fr))" gap={1}>
            {logos ?
                <>
                    {logos.map((logo: any) => {
                        return <>
                            <LogoCard
                                allowSelection={false}
                                isDarkMode={isDarkMode}
                                logo={{ url: getUrl(logo.name), id: logo.id, name: logo.name, nameOverride: logo.nameOverride, tag: logo.tag }}
                                menuActions={[
                                    {
                                        icon: <Download />,
                                        onClick: () => handleDownload(logo.id)
                                    },
                                    {
                                        icon: <Delete />,
                                        onClick: () => handleBrandLogoDelete(logo.id)
                                    }
                                ]}
                                onClick={() => navigate(fetchRoute(ERoute[flowName].logo, { logoId: logo.id, id: brandId }))}
                            />
                        </>
                    })
                    }
                    {isThereAPrimaryLogo(logos) ?
                        < AddCard
                            title={t(translations.ADD_LOGO_VARIATION)}
                            description={t(translations.LOGO_TILE_DESC)}
                            onClick={() => {
                                navigate(fetchRoute(ERoute[flowName].newLogo, { id: brandId }))
                            }}
                        />
                        :
                        <AddCard
                            title={t(translations.ADD_PRIMARY_LOGO)}
                            description={t(translations.LOGO_PRIMARY_TILE_DESC)}
                            onClick={() => {
                                navigate(fetchRoute(ERoute[flowName].newLogo, { id: brandId }))
                            }}
                        />
                    }
                </>
                :
                <CircularProgress sx={{ margin: "auto", display: "block", position: "absolute", left: "50vw", top: "50vh" }} color="primary" />
            }
            <AlertDialog
                title={t(translations.COMFIRM_DELETE, { data: "logo?" })}
                primaryButtonProps={{ loading: primaryBtnLoading, label: "yes", onClick: () => openDeleteDlg && deleteBrandLogo(openDeleteDlg) }}
                secondaryButtonProps={{
                    label: "cancel",
                    onClick: () => { setOpenDeleteDlg(null) }
                }} content={''}
                open={openDeleteDlg !== null}
                handleClose={() => { setOpenDeleteDlg(null) }}
            />
        </Box>
    </>
}

export default LogosListing