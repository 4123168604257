import { Card, Box, IconButton, Checkbox, CardHeader } from "@mui/material"
import React, { ReactNode, useState } from "react"
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material'

import IconWithContrast from "../ComponentsWithContrast/IconWithContrast"
import { TAssetCardProps } from "../../types/TAssetCardProps"
import CheckboxWithContrast from "../ComponentsWithContrast/CheckboxWithContrast"

const AssetCard = (props: TAssetCardProps) => {
    const [showCheckbox, setShowCheckbox] = useState(false)
    const onMouseOut = () => { if (props.allowSelection) setShowCheckbox(false) }
    const onMouseOver = () => { if (props.allowSelection) setShowCheckbox(true) }
    function renderMenuActions() {
        return props.menuActions?.map((action: { icon: ReactNode, onClick: any }, index: number) => {
            function handleMenuItemClick(e: React.MouseEvent<HTMLElement>) {
                e.stopPropagation()
                action.onClick(e)
            }
            return (<IconButton key={`menu_${index}`} onClick={handleMenuItemClick}><IconWithContrast bgColor={props.bgColor}>{action.icon} </IconWithContrast></IconButton>)
        })
    }

    const renderCardHeader = () => {
        if (props.menuActions && props.menuActions?.length > 0)
            return (<CardHeader
                sx={{ p: 0, justifyContent: "space-between", alignItems: "center" }}
                avatar={(props.selectionMode || showCheckbox) &&
                    <CheckboxWithContrast
                        bgColor={props.bgColor}
                        inputProps={{ 'aria-label': 'check image' }}
                        checked={props.selected}
                        onClick={(e: any) => { e.stopPropagation(); if (props.toggleSelected) props.toggleSelected(e) }}
                        icon={<IconWithContrast bgColor={props.bgColor}>
                            <CheckBoxOutlineBlankIcon aria-label="checkbox" />
                        </IconWithContrast>}
                    />
                }
                action={
                    <Box sx={{ display: "flex", flexDirection: "row", p: 2 }}>
                        {renderMenuActions()}
                    </Box>
                }
            />)
        else return <></>
    }
    return <>
        <Card
            data-testid='asset-card'
            sx={{
                position: "relative", height: 0,
                paddingBottom: "50%",
                overflow: "hidden",
                ':hover': {
                    boxShadow: 20,
                    cursor: "pointer"
                }, ...props.sx
            }}
            onClick={props.onCardClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <Box
                sx={{
                    width: "100%", height: "100%", position: "absolute",
                    borderRadius: "4px",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: "flex", justifyContent: "space-between", flexDirection: "column"
                }}>
                {props.menuActions && props.menuActions?.length > 0 &&
                    renderCardHeader()

                }
                {props.children}
            </Box>
        </Card >


    </>
}

export default AssetCard