import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import { Autocomplete, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { FirebaseContext, tagBrand } from "../../services/firebase"
import { IAppContext } from "../../interfaces/IAppContext"
import { translations } from '../../utils/Translations'

type TTagBrandDialog = {
    account: string
    brandId: string
    onClose: any
    snackbar: CallableFunction
}

export function PublishBrandDialog({ account, brandId, onClose, snackbar }: TTagBrandDialog) {
    const [tagName, setTagName] = useState<string>("")
    const [loading, setLoading] = useState(false)
    const unsubscribeListTags = useRef<any>({})
    const { api } = useContext<IAppContext>(FirebaseContext)
    const tags = useSelector((state: any) => state.tags.tags)
    const { t } = useTranslation()

    function listTags() {
        unsubscribeListTags.current = api.listTags(account, brandId)
    }

    function handleTag(e: any) {
        setTagName(e.currentTarget.value)
    }

    function publish() {
        setLoading(true)
        tagBrand({ brandId: brandId, tagName: [tagName], accountId: account })
            .then(() => {
                setLoading(false)
                snackbar({ children: `Brand successfully published to ${tagName}`, severity: 'success' })
                onClose()
            })
            .catch((error: any) => {
                setLoading(false)
                snackbar({ children: `Brand not published to ${tagName}: ${t(error.message)}`, severity: 'error' })
                onClose()
            })
    }

    useEffect(listTags, [])

    return <>
        {tags ?
            <Dialog open onClose={onClose}>
                < Container sx={{ padding: 2 }
                }>
                    <DialogTitle>{t(translations.PUBLISH_BRAND)}</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            value={tagName}
                            onChange={(event: any, newValue: any) => setTagName(newValue)}
                            freeSolo
                            disableClearable
                            options={tags.map((tag: any) => tag.id)}
                            renderInput={(params) => (
                                <TextField {...params}
                                    value={tagName}
                                    onChange={handleTag}
                                    label={t(translations.ENTER_DESTINATION)} variant="standard"
                                />
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>{t(translations.CANCEL)}</Button>
                        <Button onClick={publish}></Button>
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            variant="contained"
                            color='primary'
                            startIcon={loading ? <SaveIcon /> : ""}
                            onClick={publish} autoFocus
                        >
                            {t(translations.PUBLISH)}
                        </LoadingButton>
                    </DialogActions>
                </Container >
            </Dialog >
            : null
        }
    </>
}