import { Checkbox } from "@mui/material"
import { t } from "i18next"
import { translations } from "../../utils/Translations"

export function MakePrimaryCheckbox({ isPrimary }: { isPrimary: boolean }) {
    return <span>
        <Checkbox
            sx={{
                padding: 0,
                paddingRight: 1,
                color: 'white', '&.Mui-checked': { color: 'white' },
                cursor: isPrimary ? 'default' : 'pointer'
            }}
            disableRipple={isPrimary}
            disableFocusRipple={isPrimary}
            checked={isPrimary}
        />
        {t(isPrimary ? translations.PRIMARY : translations.MAKE_PRIMARY)}
    </span>
}

export default MakePrimaryCheckbox