import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './containers/App/App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import FirebaseProvider from './services/firebase'
import store from "./store"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import LocizeBackend from "i18next-locize-backend"
import { initReactI18next } from "react-i18next"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Cookies from 'universal-cookie'

// TODO Need to add the version numnber to the bundler  
try {
    const package_json = require('../package.json')
    if (package_json?.version)
        (window as any).version = package_json.version
} catch (error) {
    console.warn(
        "an error occurred on fetching the version, error details : ",
        error
    )
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
const cookies = new Cookies()
const params = new URLSearchParams(window.location.search) as URLSearchParams
if (params.get("lng"))
    cookies.set('lng', params.get("lng"), { path: '/' })
i18next
    .use(LocizeBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            projectId: "641348ec-8c71-43a1-af36-c0a79e9f6d31",
            //Blocker :need to update the deploy the env variables file properly
            //process.env.REACT_APP_LOCIZE_PORJECT_ID,
            referenceLng: "en-US",
            lng: "en-US"
        },
        detection: {
            order: ['querystring', 'cookie', 'navigator', 'localStorage'],
            lookupQuerystring: 'lng',
            lookupCookie: 'lng',
            lookupLocalStorage: 'lng'
        },
        fallbackLng: "en-US",
        ns: "BrandRepo"

    })
root.render(
    <Provider store={store}>
        <FirebaseProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </BrowserRouter>
        </FirebaseProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
