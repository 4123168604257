
export const ERoute = {
    "default": {
        home: "home",
        signup: "signup",
        signin: "signin",
        brandSetup: "brandSetup",
        verifyEmail: "verifyEmail",
        authAccount: "authAccount",
        dashboard: "dashboard",
        brand: "brand",
        tag: "tag",
        people: "people",
        brandAssets: "brandAssets",
        newColor: "newColor",
        colorEdit: "colorEdit",
        newLogo: "newLogo",
        logo: "logo"
    },
    "guided": {
        dashboard: "guided",
        newLogo: "guidedNewLogo",
        logo: "guidedLogo",
        colorEdit: "guidedColorEdit",
        newColor: "guidedNewColor",
        brandAssets: "guidedBrandAssets",
        home: "guidedHome",
        signup: "guidedSignup",
        signin: "guidedSignin",
        verifyEmail: "guidedVerifyEmail"
    }

    //templatePreview: "/public/templates/:id([0-9a-zA-Z%]+)",
}

export function fetchRoute(route: string, params?: any): string {
    const routes: any = {
        home: "/home",
        signup: "/auth/signup",
        signin: "/auth/signin",
        brandSetup: "/brandSetup",
        authAccount: "/auth/accounts",
        dashboard: "/dashboard",
        verifyEmail: "/auth/verifyemail",
        brand: "/brand/:id",
        tag: "/brand/:id/tag/:tag",
        people: "/people",
        brandAssets: "/brand/:id/assets/:tab",
        newColor: "/brand/:id/assets/colors/new",
        newLogo: "/brand/:id/assets/logos/new",
        logo: "/brand/:id/assets/logos/:logoId",
        colorEdit: "/brand/:id/assets/colors/:colorId",
        guidedLogo: `/guided/brand/:id/assets/logos/:logoId${location.search}`,
        guided: `/guided${location.search}`,
        guidedColorEdit: `/guided/brand/:id/assets/colors/:colorId${location.search}`,
        guidedNewColor: `/guided/brand/:id/assets/colors/new${location.search}`,
        guidedBrandAssets: `/guided/brand/:id/assets/:tab${location.search}`,
        guidedNewLogo: `/guided/brand/:id/assets/logos/new${location.search}`,
        guidedHome: `/guided/home${location.search}`,
        guidedSignup: `/guided/auth/signup${location.search}`,
        guidedSignin: `/guided/auth/signin${location.search}`,
        guidedVerifyEmail: `/guided/auth/verifyemail${location.search}`
    }

    if (!Object.keys(routes).includes(route)) {
        console.error(`Route : ${route} doesn't exist`)
        return ""
    }
    return replaceParams(routes[route], params)
}

function replaceParams(route: string, params?: any) {
    if (!params) return route

    let rRoute = route
    Object.keys(params).forEach((param, index) => {
        if (!params[param] && params[param] !== 0) {
            console.error(
                `Route param : ${param}, doesn't exist on route : ${route} `
            )
        } else {
            rRoute = rRoute.replace(new RegExp(`:${param}`), params[param].toString())
        }
    })

    return rRoute
}

export default fetchRoute