import { logEvent } from "firebase/analytics"
import { addDoc, collection, deleteDoc, doc, DocumentReference, DocumentSnapshot, getDoc, onSnapshot, orderBy, query, QuerySnapshot, setDoc, updateDoc } from "firebase/firestore"
import React from "react"
import { IColors } from "../interfaces/IColors"
import { IColorsColor } from "../interfaces/IColorsColor"
import { IColorsColorAs } from "../interfaces/IColorsColorAs"
import { IColorsDuplicate, IColorsId } from "../interfaces/IColorsId"
import { analytics, db } from "../services/firebase"
import { Color } from "./Color"

export class Colors {
    static list({ accountId, brandId, dispatch }: { accountId: string, brandId: string, dispatch: React.Dispatch<React.SetStateAction<Color[] | null>> }) {
        function updateProps(querySnap: QuerySnapshot) {
            // hold colors to return
            const rColors: any[] = []
            // hold color tags (which are colors with a non-random ID and a .ref property)
            const colorTags: any[] = []

            querySnap.forEach((qsdoc: DocumentSnapshot) => {
                const data: any = qsdoc.data()
                if (data.ref) {
                    // this is a color tag
                    colorTags.push({
                        id: qsdoc.ref.id,
                        ref: data.ref,
                        createdOn: data.createdOn
                    })
                } else {
                    // this is a color
                    rColors.push(new Color({
                        id: qsdoc.ref.id,
                        name: data.name,
                        value: data.value,
                        ref: qsdoc.ref,
                        createdOn: data.createdOn
                    }))
                }
            })

            // tag corresponding colors
            for (const colorTag of colorTags) {
                const color: any = rColors.find(c => c.id === colorTag.ref)
                if (color) { color.tag = colorTag.id }
            }
            dispatch(rColors)
        }

        return onSnapshot(query(
            collection(db, `/accounts/${accountId}/brands/${brandId}/colors`),
            orderBy('createdOn', 'asc')
        ), updateProps)
    }

    static async add({ accountId, brandId, color }: IColors): Promise<Color> {
        if (!color.name || !color.value) throw new Error("Invalid color payload")
        // TODO: Validate HEX color before storing
        const newColor: any = {
            name: color.name,
            value: color.value,
            createdOn: new Date()
        }
        return addDoc(collection(db, `/accounts/${accountId}/brands/${brandId}/colors`), newColor)
            .then((docRef: DocumentReference) => {
                logEvent(analytics, 'color_add', {
                    account_id: accountId,
                    brand_id: brandId,
                    color_id: docRef.id,
                    color_name: color.name,
                    color_value: color.value
                })
                return new Color({
                    id: docRef.id,
                    ref: docRef,
                    name: newColor.name,
                    value: newColor.value,
                    createdOn: newColor.createdOn
                })
            })
    }

    static async delete({ accountId, brandId, colorId }: IColorsId) {
        return deleteDoc(doc(db, `/accounts/${accountId}/brands/${brandId}/colors/${colorId}`))
            .then(() => {
                logEvent(analytics, 'color_delete', {
                    color_id: colorId
                })
            })
    }

    static async duplicate({ accountId, brandId, color }: IColorsDuplicate): Promise<Color> {
        return Colors
            .add({
                accountId: accountId,
                brandId: brandId,
                color: {
                    name: `${color.name} (copy)`,
                    value: color.value
                }
            })
    }

    static async get({ accountId, brandId, colorId }: IColorsId): Promise<Color> {
        return getDoc(doc(db, `/accounts/${accountId}/brands/${brandId}/colors/${colorId}`))
            .then((sourceDoc: DocumentSnapshot) => {
                if (!sourceDoc.exists) {
                    throw new Error("Color does not exist")
                }
                const data: any = sourceDoc.data()
                return new Color({
                    id: sourceDoc.ref.id,
                    ref: sourceDoc.ref,
                    name: data.name,
                    value: data.value,
                    createdOn: data.createdOn
                })
            })
    }

    static async update({ accountId, brandId, color }: IColorsColor): Promise<Color> {
        const updatePayload = {
            name: color.name,
            value: color.value
        }
        const docRef = doc(db, `/accounts/${accountId}/brands/${brandId}/colors/${color.id}`)
        return updateDoc(docRef, updatePayload)
            .then(async () => {
                return getDoc(docRef)
                    .then((docsnap: DocumentSnapshot) => {
                        const data: any = docsnap.data()
                        logEvent(analytics, 'color_update', {
                            account_id: accountId,
                            brand_id: brandId,
                            color_id: docRef.id,
                            color_name: data.name,
                            color_value: data.value
                        })
                        return new Color({
                            id: docRef.id,
                            ref: docRef,
                            name: data.name,
                            value: data.value,
                            createdOn: data.createdOn
                        })
                    })
            })
    }

    static async tag({ accountId, brandId, color, as }: IColorsColor & IColorsColorAs) {
        const newTag = {
            name: as,
            ref: color.id,
            createdOn: new Date()
        }
        return setDoc(doc(db, `/accounts/${accountId}/brands/${brandId}/colors/${as}`), newTag)
    }
}