import { Box } from "@mui/material"
import { t } from "i18next"
import { useState } from "react"
import Logo from "../../components/Logo/Logo"
import { loginWithEmailAndPassword } from "../../services/firebase"
import { ERoute } from '../../utils/routes'
import { translations } from "../../utils/Translations"
import { getFlowName } from "../../utils/utils"
import { ESignInUpError } from "../../types/ESignInUpError"
import SignButtonGroup from "./SignButtonGroup"
import SignHeader from "./SignHeader"
import { ResetPasswordForm } from "./ResetPasswordForm"
import { SignWithEmailForm } from "./SignWithEmailForm"

export enum ESignInStep {
    SIGNUP_OPTIONS = "signupOptions",
    EMAIL = "email",
    RESET_PASSWORD = "resetPassword",
}

const SignIn = () => {
    const [step, setStep] = useState<ESignInStep>(ESignInStep.SIGNUP_OPTIONS)
    const [error, setError] = useState<ESignInUpError>()

    function handleSignIn(email: string, password: string) {
        loginWithEmailAndPassword(email, password)
            .catch((ex: any) => {
                if (ex.message === "Email not verified") {
                    setError(ESignInUpError.EMAIL_NOT_VERIFIED)
                } else {
                    setError(ESignInUpError.EMAIL_OR_PASSWORD_ERROR)
                }
            })
    }

    const flowName = getFlowName()

    return <>
        <Box sx={{ display: "flex", flexDirection: "column", p: 2, width: "100%" }}>
            <Logo />
            <Box sx={{ alignSelf: "center", maxWidth: "25rem", flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <SignHeader
                    navigateTo={ERoute[flowName].signup}
                    navigateToLabel={t(translations.SIGN_UP)}
                    subtitle={t(translations.NO_ACCOUNT)}
                />
                {step === ESignInStep.SIGNUP_OPTIONS &&
                    <SignButtonGroup
                        signWithGoogleButtonProps={{ label: t(translations.SIGN_IN_GOOGLE) }}
                        signWithEmailButtonProps={{ label: t(translations.SIGN_IN_EMAIL), onClick: () => { setStep(ESignInStep.EMAIL) } }}
                    />
                }
                {step === ESignInStep.EMAIL &&
                    <SignWithEmailForm
                        validatePassword={false}
                        setError={setError}
                        setStep={setStep}
                        error={error}
                        signButtonProps={{ label: t(translations.SIGN_IN), onClick: handleSignIn }}
                        footerLink={{ label: t(translations.SIGN_IN_DIFFERENTLY), onClick: () => { setStep(ESignInStep.SIGNUP_OPTIONS) } }}
                    />
                }
                {step === ESignInStep.RESET_PASSWORD &&
                    <ResetPasswordForm
                        setError={setError}
                        error={error}
                        signButtonProps={{ label: t(translations.RESET_PASSWORD), onClick: handleSignIn }}
                        footerLink={{ label: t(translations.SIGN_IN_EMAIL), onClick: () => { setStep(ESignInStep.EMAIL) } }}
                    />
                }
            </Box>
        </Box>
    </>
}

export default SignIn