import { combineReducers } from 'redux'

const initialBrandsState: any = {
    brands: [],
    brand: null,
    singleLogo: undefined,
    components: {
        sidebarVisibility: true
    }
}

function brandsReducer(state = initialBrandsState, action: { type: any; payload: { color: any; todoId: any } }) {
    switch (action.type) {
        case "setBrands": {
            return {
                ...state,
                brands: action.payload
            }
        }
        case "setBrand": {
            return { ...state, brand: action.payload }
        }
        case "setBrandSingleLogo": {
            return {
                ...state,
                singleLogo: { ...state.singleLogo, ...action.payload }
            }
        }
        case "reset": {
            return initialBrandsState
        }
        default:
            return state
    }
}

const initialTagsState: any = {
    tags: null
}

function tagsReducer(state = initialTagsState, action: { type: any; payload: { color: any; todoId: any } }) {
    switch (action.type) {
        case "setTags": {
            return {
                ...state,
                tags: action.payload
            }
        }
        case "reset": {
            return initialTagsState
        }
        default:
            return state
    }
}
const initialUIState: any = {
    publishedOn: 0,
    updatedOn: 0,
    logosListing: {
        isDarkMode: false
    }

}
function uiReducer(state = initialUIState, action: { type: any; payload: any }) {
    switch (action.type) {
        case "setPublishedOn": {
            return {
                ...state,
                publishedOn: action.payload
            }
        }
        case "setAssetsChangeTracker": {
            return {
                ...state,
                updatedOn: action.payload
            }
        }
        case "setLogosListingDarkMode": {
            return {
                ...state,
                logosListing: {
                    ...state.logosListing,
                    isDarkMode: action.payload
                }
            }
        }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    brands: brandsReducer,
    tags: tagsReducer,
    uiState: uiReducer
})

export default rootReducer
