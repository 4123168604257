import styled from "@emotion/styled"

const StyledTextWithContrast = styled.div((props: any) => {
    // we need to calculate the luminance of the background color
    // to determine if the text should be light or dark
    let luminance = 0
    if (props.bgColor?.replace(/^#/, "")?.match(/.{2}/g)) {
        luminance = props
            .bgColor
            .replace(/^#/, "")
            .match(/.{2}/g)
            .reduce((a: string, b: string) => a + parseInt(b, 16), 0)
    }
    return {
        color: luminance > 382 ? "#000000" : "#ffffff"
    }
})

function TextWithContrast(props: any) {
    return <StyledTextWithContrast {...props} />
}

export default TextWithContrast
