import { Box, Tab, Tabs } from '@mui/material'
import { Unsubscribe } from 'firebase/firestore'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from "react-router"
import TabPanel, { a11yProps } from '../../components/TabPanel/TabPanel'
import { IAppContext } from "../../interfaces/IAppContext"
import { IBrandAssets } from '../../interfaces/IBrandAssets'
import { Color } from "../../models/Color"
import { Colors } from '../../models/Colors'
import { Logo } from '../../models/Logo'
import { Logos } from '../../models/Logos'
import { FirebaseContext } from "../../services/firebase"
import fetchRoute, { ERoute } from "../../utils/routes"
import { getDefaultNameOfNewColor, getFlowName, isThereAPrimaryColor, isThereAPrimaryLogo } from '../../utils/utils'
import PageLayout from '../Layouts/PageLayout'
import AddAssetButton from "./AddAssetButton"
import AddColor from './Colors/AddColor'
import ColorEdit from './Colors/ColorEdit'
import ColorsListing from "./Colors/ColorsListing"
import LogosListing from './Logos/LogosListing'

enum BrandAssetsTopLevelScreen {
    TABS = 0,
    LOGOS = 1,
    COLOR_EDIT = 2,
    COLOR_NEW = 3
}

export default function BrandAssets({ account, pageTitle, user, accounts, setAccount, setSnackbar, logout }: IBrandAssets) {
    const [value, setValue] = useState(0)
    const { api } = useContext<IAppContext>(FirebaseContext)
    const { id, tab } = useParams<string>()
    const navigate = useNavigate()
    const unsubscribeGetBrand = useRef({})
    const [level, setLevel] = useState<BrandAssetsTopLevelScreen>(BrandAssetsTopLevelScreen.TABS)
    const [color, setColor] = useState<Color>()
    const [logos, setLogos] = useState<Logo[] | null>(null)
    const [colors, setColors] = useState<Color[] | null>(null)
    const [newPrimary, setNewPrimary] = useState<boolean>(false)
    const { t } = useTranslation()
    const { ui } = useContext(FirebaseContext)

    if (!id) { throw new Error("no brand id") }

    function handleChange(event: React.SyntheticEvent, newValue: number) {
        setValue(newValue)
    }

    function duplicateBrandColor(colorToDuplicate: Color) {
        if (id) {
            Colors.duplicate({
                accountId: account,
                brandId: id,
                color: colorToDuplicate
            }).then(() => {
                // Track changes
                ui.setAssetsChangeTracker(Date.now())
            })
        }
    }

    function getBrandDocument() {
        if (account && id) {
            unsubscribeGetBrand.current = api.getBrand(account, id)
        }
        return () => {
            if (unsubscribeGetBrand?.current && typeof unsubscribeGetBrand?.current === "function")
                unsubscribeGetBrand.current()
        }
    }

    function changeTab() {
        if (Number(tab) >= 0) {
            setValue(Number(tab))
        }
    }

    function listColors(): Unsubscribe {
        return Colors.list({ accountId: account, brandId: String(id), dispatch: setColors })
    }

    function getLogos() {
        return Logos.list({ accountId: account, brandId: String(id), dispatch: setLogos })
    }

    const flowName = getFlowName()

    function handleColorEdit(c: Color) {
        setColor(c)
        setLevel(BrandAssetsTopLevelScreen.COLOR_EDIT)
    }

    function handleNewColor(primary?: boolean) {
        setNewPrimary(primary || false)
        setLevel(BrandAssetsTopLevelScreen.COLOR_NEW)
    }

    function checkIfBrandHasMinReqToPublish() {
        return isThereAPrimaryColor(colors) || isThereAPrimaryLogo(logos)
    }

    useEffect(getBrandDocument, [account])
    useEffect(changeTab, [tab])
    useEffect(listColors, [account, id])
    useEffect(getLogos, [account, id])

    return <>
        {level === BrandAssetsTopLevelScreen.TABS &&
            <PageLayout
                pageTitle={pageTitle}
                account={account}
                isBrandHasMinReqToPublish={checkIfBrandHasMinReqToPublish()}
                rightComponent={
                    <AddAssetButton
                        handleAddColor={() => { setLevel(BrandAssetsTopLevelScreen.COLOR_NEW) }}
                        handleAddLogo={() => { navigate(fetchRoute(ERoute[flowName].newLogo, { id: id })) }}
                    />
                }
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                user={user}
                accounts={accounts}
                logout={logout}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: "space-between", alignItems: "baseline", display: "flex" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="asset-tabs">
                        <Tab label={t('app-general-logos')} {...a11yProps(0)} />
                        <Tab label={t('app-general-colours')} {...a11yProps(1)} />
                    </Tabs>
                </Box >
                <TabPanel value={value} index={0}>
                    <LogosListing brandId={id} account={account} logos={logos} />
                </TabPanel >
                <TabPanel value={value} index={1}>
                    {id &&
                        <ColorsListing
                            duplicateBrandColor={duplicateBrandColor}
                            colorEdit={handleColorEdit}
                            newColor={handleNewColor}
                            colors={colors}
                        />
                    }
                </TabPanel>
            </PageLayout >
        }
        {
            level === BrandAssetsTopLevelScreen.COLOR_EDIT &&
            <ColorEdit
                account={account}
                color={color}
                close={() => setLevel(BrandAssetsTopLevelScreen.TABS)}
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                user={user}
                accounts={accounts}
                logout={logout}
            />
        }
        {
            level === BrandAssetsTopLevelScreen.COLOR_NEW &&
            <AddColor
                account={account}
                defaultPrimary={newPrimary}
                defaultName={getDefaultNameOfNewColor(colors)}
                close={() => {
                    setLevel(BrandAssetsTopLevelScreen.TABS)
                    setNewPrimary(false)
                }}
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                user={user}
                accounts={accounts}
                logout={logout}
            />
        }
    </>
}