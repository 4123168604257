import { collection, deleteDoc, doc, DocumentReference, getDocs, QueryDocumentSnapshot, QuerySnapshot, setDoc } from "firebase/firestore"
import { db } from "../services/firebase"
import { hash } from "./Logos"

export class Logo {
    readonly id: string
    readonly ref: DocumentReference
    name: string
    nameOverride?: string
    tag?: string
    darkmode: boolean

    constructor({ id, ref, name, nameOverride, tag, darkmode }: { id: string; ref: DocumentReference; name: string; nameOverride: string; tag?: string, darkmode: boolean }) {
        this.id = id
        this.ref = ref
        this.name = name
        this.nameOverride = nameOverride
        this.tag = tag
        this.darkmode = darkmode
    }

    async createImage({ name }: { name: string }) {
        const h = hash(name)
        return setDoc(doc(db, this.ref.path + `/files/${h}`), { name: name }, { merge: true })
    }

    /**
     * Recursive delete of all files and the logo itself
     */
    async delete() {
        return getDocs(collection(db, this.ref.path + '/files'))
            .then(async (qs: QuerySnapshot) => {
                const promises = qs.docs.map((qsdoc: QueryDocumentSnapshot) => deleteDoc(qsdoc.ref))
                return Promise.allSettled(promises).then(() => {
                    deleteDoc(this.ref)
                })
            })
    }

    async rename(newName: string) {
        this.nameOverride = newName
        return this.save()
    }

    async save() {
        const updateDoc: any = {
            darkmode: this.darkmode
        }

        if (this.nameOverride) {
            updateDoc.nameOverride = this.nameOverride
        }

        return setDoc(this.ref, updateDoc, { merge: true })
    }
}
