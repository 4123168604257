import { Box, useTheme } from "@mui/material"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import AlertDialog from "../../../components/AlertDialog/AlertDialog"
import AssetLayout from "../../../components/AssetLayouts/AssetLayout"
import FileUploader from "../../../components/FileUploader/FileUploader"
import MakePrimaryCheckbox from "../../../components/MakePrimaryCheckbox/MakePrimaryCheckbox"
import { Logos } from "../../../models/Logos"
import { FirebaseContext } from "../../../services/firebase"
import { IAppContext } from "../../../interfaces/IAppContext"
import { ILogoAdd } from "../../../interfaces/ILogoAdd"
import fetchRoute, { ERoute } from "../../../utils/routes"
import { translations } from "../../../utils/Translations"
import { getDefaultNameOfNewLogo, getFlowName } from "../../../utils/utils"
import { allowedExtensions } from "./consts"
import LogoExtensionView from "./LogoExtensionView"


const AddLogo = ({ account, user, accounts, setSnackbar, setAccount, logout }: ILogoAdd) => {
    const [logoName, setLogoName] = useState<string>("variation")
    const [logos, setLogos] = useState<any[] | null>(null)
    const [markAsPrimary, setMarkAsPrimary] = useState<boolean>(false)
    const [openPrimaryDialog, setOpenPrimaryDialog] = useState<boolean>(false)
    const { t } = useTranslation()
    const { ui } = useContext<IAppContext>(FirebaseContext)
    const navigate = useNavigate()
    const theme = useTheme()
    const { id: brandId } = useParams<string>()

    if (!brandId) throw new Error("error")

    function handleNameChange(e: ChangeEvent<HTMLInputElement>) {
        setLogoName(e.target.value)
    }

    function getLogos() {
        if (brandId) return Logos.list({ accountId: account, brandId: brandId, dispatch: setLogos })
    }

    function handleAddLogo() {
        navigate(fetchRoute(ERoute[flowName].brandAssets, { id: brandId, tab: 0 }))
    }

    const flowName = getFlowName()

    function onUploadSuccess() {
        // Track changes
        if (markAsPrimary && brandId) {
            Logos.forceTag({ accountId: account, brandId: brandId, logoName: logoName, as: 'Primary' })
        }
        ui.setAssetsChangeTracker(Date.now())
        navigate(fetchRoute(ERoute[flowName].brandAssets, { id: brandId, tab: 0 }))
    }

    function setMarkAsPrimaryAndCloseDialog() {
        setOpenPrimaryDialog(false)
        setMarkAsPrimary(true)
    }

    // TODO : this needs to be improved by having the data saved in redux and accessed directly here
    // Keeping it like that for now, till it is fixed everywhere else
    useEffect(getLogos, [account, brandId])
    useEffect(() => {
        if (logos) {
            const newDefaultName = getDefaultNameOfNewLogo(logos)
            if (logos.length === 0) {
                setMarkAsPrimary(true)
                setLogoName(newDefaultName)
            }
            else {
                setLogoName(newDefaultName)
            }
        }
    }, [logos])

    return <>
        <AssetLayout
            assetType='Logo'
            backFunction={handleAddLogo}
            addButtonProps={[{
                children: <MakePrimaryCheckbox isPrimary={markAsPrimary} />,
                disableFocusRipple: markAsPrimary,
                disableRipple: markAsPrimary,
                disableElevation: markAsPrimary,
                sx: {
                    cursor: markAsPrimary ? 'default' : 'pointer',
                    "&:hover": { bgcolor: theme.palette.primary.main }
                },
                onClick: () => {
                    if (!markAsPrimary) {
                        setMarkAsPrimaryAndCloseDialog()
                    }
                }
            }]}
            inputProps={{ onChange: handleNameChange, value: logoName, id: "name", placeholder: t(translations.UNTITLED_LOGO) as string }}
            account={account}
            setSnackbar={setSnackbar}
            setAccount={setAccount}
            user={user}
            accounts={accounts}
            logout={logout}
        >
            <>
                <Box sx={{ display: "flex", mb: 2 }}>
                    {
                        allowedExtensions.map((ext: string, index: number) => <LogoExtensionView key={`logo_${ext}_${index}`} extension={ext} added={false} />)
                    }
                </Box>
                <FileUploader
                    logoName={logoName}
                    account={account}
                    brandId={brandId}
                    showCloseBtn={false}
                    handleClose={() => navigate(fetchRoute(ERoute[flowName].brandAssets, { id: brandId, tab: 0 }))}
                    currentExtensions={[]}
                    onFileUploaded={(e: any) => {
                        Logos.create({ accountId: account, brandId: brandId, logoName: logoName })
                    }}
                    onSuccess={onUploadSuccess}
                />
            </>
        </AssetLayout>
        <AlertDialog
            key="confirm_primary_logo_alert_dialog"
            title={t(translations.COMFIRM_LOGO_PRIMARY)}
            primaryButtonProps={{ label: "yes", onClick: () => { openPrimaryDialog && setMarkAsPrimaryAndCloseDialog() } }}
            secondaryButtonProps={{
                label: t(translations.CANCEL),
                onClick: () => { setOpenPrimaryDialog(false) }
            }} content={''}
            open={openPrimaryDialog}
            handleClose={() => { setOpenPrimaryDialog(false) }}
        />
    </>
}

export default AddLogo