import { Typography } from "@mui/material"
import React from "react"
import { ILabelButton } from "../../interfaces/ILabelButton"
import SignWithEmailButton from "./SignWithEmailButton"
import SignWithGoogleButton from "./SignWithGoogleButton"

const btnStyle = { width: "-webkit-fill-available", mt: 2, mb: 2 }

interface ISignButtonGroup {
    signWithEmailButtonProps: ILabelButton
    signWithGoogleButtonProps: ILabelButton
}

const SignButtonGroup = ({ signWithEmailButtonProps, signWithGoogleButtonProps }: ISignButtonGroup) => {
    return <>
        <React.Fragment>
            <SignWithGoogleButton sx={btnStyle}  {...signWithGoogleButtonProps} />
            <Typography variant="h5" sx={{ textAlign: "center" }}>OR</Typography>
            <SignWithEmailButton {...signWithEmailButtonProps} sx={btnStyle} label={signWithEmailButtonProps.label} onClick={signWithEmailButtonProps.onClick} />
        </React.Fragment>
    </>
}

export default SignButtonGroup