import { Visibility, VisibilityOff } from "@mui/icons-material"
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import { Button, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Typography } from "@mui/material"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { ISignWithEmailForm } from "../../interfaces/ISignWithEmailForm"
import { TEmailState } from "../../types/TEmailState"
import { TPasswordState } from "../../types/TPasswordState"
import { translations } from "../../utils/Translations"
import { PASSWORD_REQUIREMENTS, validateEmail, validatePasswordStrength } from "../../utils/Validators"
import { ESignInUpError } from "../../types/ESignInUpError"
import { ESignInStep } from "./SignIn"

export function SignWithEmailForm({ validatePassword, setError, setStep, signButtonProps, error, footerLink }: ISignWithEmailForm) {
    const [password, setPassword] = useState<TPasswordState>({ value: "" })
    const [email, setEmail] = useState<TEmailState>({ value: "" })
    const [showPassword, setShowPassword] = useState<boolean>(false)

    function handlePasswordChange(e: any) {
        const passwordValue = e.target.value
        if (password.error && validatePassword) {
            setPassword({ ...validatePasswordStrength(passwordValue), value: passwordValue })
            return
        }
        setPassword({ ...password, value: passwordValue })
    }

    function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()
    }

    function handleClickShowPassword() {
        setShowPassword((show) => !show)
    }

    function handleEmailChange(e: any) {
        const emailValue = e.target.value
        if (email.error) {
            setEmail({ ...validateEmail(emailValue), value: emailValue })
            return
        }
        setEmail({ ...email, value: emailValue })
    }


    function validateEmailHandler() {
        const updatedEmail = validateEmail(email.value)
        setEmail({ ...email, ...updatedEmail })
    }

    function validatePasswordHandler() {
        if (validatePassword) {
            const passwordValidator = validatePasswordStrength(password.value)
            setPassword({ ...password, ...passwordValidator })
        }
    }

    const renderPasswordErrorsList = () => {
        if (password.message) {
            if (password.message.includes("empty")) {
                return <li key={`empty`}><Typography color={"error"}>{t(`app-validation-pwd_empty`)}</Typography></li>
            }
            const reqErrors = PASSWORD_REQUIREMENTS.some(r => password?.message && password.message.includes(r))

            if (reqErrors) {
                return PASSWORD_REQUIREMENTS.map((req: string, index) => {
                    const color = password.message?.includes(req) ? "error" : "success"
                    return <li key={`${index}_${color}`}><Typography color={color}>{t(`app-validation-pwd_${req}`)}</Typography></li>
                })
            }
            if ((password.message.includes("big-size") || password.message.includes("size"))) {
                return password.message.map((req: string, index: number) => {
                    return <li key={`${index}`}><Typography color={"error"}>{t(`app-validation-pwd_${req}`)}</Typography></li>
                })
            }
        }
    }

    function signButtonHandler(e: any) {
        if (password?.error || email?.error) {
            return
        }
        signButtonProps.onClick(email?.value, password?.value)
    }

    useEffect(() => {
        return (setError(null))
    }, [])

    return <>
        <FormControl error={email?.error} fullWidth sx={{ mt: 3 }} variant="outlined">
            <InputLabel htmlFor="email">{t(translations.EMAIL)}</InputLabel>
            <OutlinedInput
                inputProps={{ shrink: true }}
                fullWidth
                value={email?.value ? email.value : ""}
                onBlur={validateEmailHandler}
                color={email?.error ? "error" : "primary"}
                id="email" type="text" label={t(translations.EMAIL)} onChange={handleEmailChange} />
            <FormHelperText id="email-helper">{email?.message}</FormHelperText>

        </FormControl>
        <FormControl
            sx={{ width: '100%', mt: 3 }} variant="outlined">
            <InputLabel
                error={password.message?.length && password.message?.length > 0 ? true : false} htmlFor="password">{t(translations.PASSWORD)}</InputLabel>
            <OutlinedInput
                error={password.message?.length && password.message?.length > 0 ? true : false}
                value={password?.value ? password.value : ""}
                onBlur={validatePasswordHandler}
                color={password?.error ? "error" : "primary"}
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end" >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                label={t(translations.PASSWORD)}
                onChange={handlePasswordChange}
            />
            <FormHelperText id="password-helper">
                {(password?.message?.length && password?.message?.length > 0) ? <ul>
                    {renderPasswordErrorsList()}
                </ul> : null}
            </FormHelperText>

        </FormControl>
        {error &&
            <Typography ml={1} mt={2} variant="body1" color="error">{error === ESignInUpError.EMAIL_OR_PASSWORD_ERROR ?
                t(translations.EMAIL_WRONG)
                :
                error === ESignInUpError.EMAIL_ALREADY_IN_USE ?
                    t(translations.EMAIL_IN_USE)
                    :
                    error === ESignInUpError.EMAIL_NOT_VERIFIED ?
                        t(translations.EMAIL_NOT_VERIFIED)
                        :
                        null
            }
            </Typography>
        }
        <Button size="large" sx={{ width: "-webkit-fill-available", mt: 2, mb: 2 }} variant='contained' onClick={signButtonHandler}>
            {signButtonProps.label}
        </Button>
        <Link component="button" onClick={() => { setStep(ESignInStep.RESET_PASSWORD) }}>{t(translations.FORGOT_PASSWORD_QUESTION)}</Link>
        <Divider sx={{ marginTop: 8, marginBottom: 3 }} />
        <Button
            variant="text"
            onClick={footerLink.onClick}
            startIcon={<ArrowBackIos />}
        >
            {footerLink.label}
        </Button>
    </>
}

