import { Button, Snackbar, SnackbarContent } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { translations } from '../../utils/Translations'

const SelectionSnackbar = ({ numberOfSelectedElements, actions }: { numberOfSelectedElements: number, actions: { label: string, onClick: Function }[] }) => {
    const [snackbar, setSnackbar] = useState(false)
    const handleCloseSnackbar = () => { setSnackbar(false) }
    const { t } = useTranslation()

    return <>
        {(snackbar || numberOfSelectedElements > 0) ?
            <Snackbar open
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <SnackbarContent
                    message={t(translations.XSELECTED, { numberOfSelectedElements: numberOfSelectedElements })}
                    action={actions.map(action => <Button variant="text" color={"inherit"} onClick={() => { handleCloseSnackbar(); action.onClick() }}>{action.label}</Button>)}
                />
            </Snackbar>
            : null
        }
    </>
}

export default SelectionSnackbar
