import { t } from "i18next"
import { translations } from "./Translations"

export const PASSWORD_REQUIREMENTS = ["lowercase", "uppercase", "number", "symbol"]

export const checkPasswordStrength = (password: string) => {
    if (!password) {
        throw new Error("Password is empty.")
    }
    const lowerCaseRegex = ".*[a-z]"
    const upperCaseRegex = "(?=.*[A-Z])"
    const symbolsRegex = "(?=.*[!#$%&'\"()*+,-./:;<=>?@\\[\\]^_`{|}~])"
    const numericRegex = "(?=.*[0-9])"

    let strength: {
        id: null | number,
        value: null | string,
        length?: null | number,
        contains?: any[]
    } = {
        id: null,
        value: null,
        contains: []
    }

    // Default
    let passwordContains: any[] = []

    if (new RegExp(`^${lowerCaseRegex}`).test(password)) {
        passwordContains = [
            ...passwordContains,
            {
                message: "lowercase"
            }
        ]
    }

    if (new RegExp(`^${upperCaseRegex}`).test(password)) {
        passwordContains = [
            ...passwordContains,
            {
                message: "uppercase"
            }
        ]
    }

    if (new RegExp(`^${symbolsRegex}`).test(password)) {
        passwordContains = [
            ...passwordContains,
            {
                message: "symbol"
            }
        ]
    }

    if (new RegExp(`^${numericRegex}`).test(password)) {
        passwordContains = [
            ...passwordContains,
            {
                message: "number"
            }
        ]
    }

    const strongRegex = new RegExp(
        `^${lowerCaseRegex}${upperCaseRegex}${numericRegex}${symbolsRegex}(?=.{8,})`
    )
    const mediumRegex = new RegExp(
        `^((${lowerCaseRegex}${upperCaseRegex})|(${lowerCaseRegex}${numericRegex})|(${upperCaseRegex}${numericRegex})|(${upperCaseRegex}${symbolsRegex})|(${lowerCaseRegex}${symbolsRegex})|(${numericRegex}${symbolsRegex}))(?=.{6,})`
    )

    if (strongRegex.test(password)) {
        strength = {
            id: 2,
            value: "Strong"
        }
    } else if (mediumRegex.test(password)) {
        strength = {
            id: 1,
            value: "Medium"
        }
    } else {
        strength = {
            id: 0,
            value: "Weak"
        }
    }
    strength.length = password.length
    strength.contains = passwordContains
    return strength
}


export function validatePasswordStrength(password: string, validateEmptyOnly = false) {
    let error = false
    let isEmpty = true
    if (password) {
        isEmpty = password.length === 0
    }

    let message: string[] = []
    let valid = "valid"

    if (isEmpty) {
        message = ["empty"]
        valid = "empty"
        error = true
    } else if (!validateEmptyOnly) {
        const passCheck = checkPasswordStrength(password)
        let difference: any[] = []
        const passContains = passCheck.contains && new Set(passCheck.contains.map((item: any) => item.message))
        difference = [...difference, ...PASSWORD_REQUIREMENTS].filter(
            (requires) => passContains && !passContains.has(requires)
        )
        error = true
        if (
            password.length > 14 ||
            (password.length > 7 && difference.length === 0)
        ) {
            error = false
        }

        if (error) {
            difference.forEach((item: any) => {
                message.push(t(`${item}`))
            }
            )
            if (passCheck?.length && passCheck.length < 7) {
                message.push("size")
            }
            if (passCheck.length && passCheck.length < 14 && difference.length > 0) {
                message.push("big-size")
            }
        }
    }
    return {
        message: message,
        error: error
    }
}

export function isValidString(str: string) {
    return str && str.trim() !== "" ? true : false
}

export function validateEmail(emailValue: any) {
    if (!isValidString(emailValue)) {
        return { error: true, message: t(translations.EMAIL_REQUIRED) }
    }
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailValue ? emailValue : "")) {
        return { error: true, message: t(translations.EMAIL_NOT_FORMATTED) }
    }
    return { error: false, message: undefined }
}
