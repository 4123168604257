import { deleteDoc, DocumentReference, setDoc } from "firebase/firestore"


export class Color {
    readonly id: string
    readonly ref: DocumentReference
    readonly createdOn: Date
    name: string
    value?: string
    tag?: string

    constructor({ id, name, value, ref, tag, createdOn }: { id: string; name: string; value?: string; ref: DocumentReference; tag?: string; createdOn: Date }) {
        this.id = id
        this.name = name
        this.value = value
        this.ref = ref
        this.tag = tag
        this.createdOn = createdOn
    }

    async save() {
        return setDoc(this.ref, { name: this.name, value: this.value }, { merge: true })
    }

    async delete() {
        return deleteDoc(this.ref)
    }
}
