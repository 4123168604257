import { Check } from "@mui/icons-material"
import { Button, ButtonProps } from "@mui/material"
import { MouseEvent, useState } from "react"

interface ICopyButton extends ButtonProps {
    copiedText: string
}

const CopyButton = (props: ICopyButton) => {
    const [clicked, setClicked] = useState(false)

    function onClick(e: MouseEvent<HTMLButtonElement>) {
        setClicked(true)
        navigator.clipboard.writeText(props.copiedText)
        setTimeout(() => {
            setClicked(false)
        }, 2000)
    }

    return <>
        <Button
            {...props}
            variant={clicked ? "contained" : "outlined"}
            endIcon={clicked ? <Check /> : null}
            color={clicked ? "success" : "primary"}
            onClick={onClick}
        >
            {clicked ? "Copied" : props.children}
        </Button >
    </>
}

export default CopyButton