import { logEvent } from "firebase/analytics"
import { collection, deleteDoc, doc, limit, onSnapshot, orderBy, query, QueryDocumentSnapshot, QuerySnapshot, Unsubscribe } from "firebase/firestore"
import React from "react"
import { analytics, db } from "../services/firebase"

interface IImagesDelete {
    accountId: string
    brandId: string
    logoId: string
    imageId: string
}

interface IImagesList {
    accountId: string
    brandId: string
    logoId: string
    dispatch: React.Dispatch<React.SetStateAction<any[] | undefined>>
}

interface IImagesGetOneForManyLogos {
    accountId: string
    brandId: string
    logos: any[]
    dispatch: React.Dispatch<React.SetStateAction<any[]>>
}

export class Images {
    static async delete({ accountId, brandId, logoId, imageId }: IImagesDelete) {
        return deleteDoc(doc(db, `/accounts/${accountId}/brands/${brandId}/assets/${logoId}/files/${imageId}`))
            .then(() => {
                logEvent(analytics, 'image_delete', {
                    account_id: accountId,
                    brand_id: brandId,
                    logo_id: logoId,
                    image_id: imageId
                })
            })
    }

    /**
     * List images for a single folder (logo)
     * @returns Unsubscribe
     */
    static list({ accountId, brandId, logoId, dispatch }: IImagesList): Unsubscribe {
        function updateImages(qs: QuerySnapshot) {
            dispatch(qs.docs.map((qsdoc: QueryDocumentSnapshot) => {
                return {
                    id: qsdoc.id,
                    ref: qsdoc.ref,
                    ...qsdoc.data()
                }
            }))
        }

        return onSnapshot(query(
            collection(db, `/accounts/${accountId}/brands/${brandId}/assets/${logoId}/files`), orderBy('updatedOn', 'desc')),
            updateImages
        )
    }

    /**
     * List images for many folders (logos)
     * @returns Unsubscribe[]
     */
    static getOneImageForManyLogos({ accountId, brandId, logos, dispatch }: IImagesGetOneForManyLogos): Unsubscribe[] {
        function updateImages(qs: QuerySnapshot) {
            dispatch(values => [
                ...values,
                ...qs.docs.map((qsdoc: QueryDocumentSnapshot) => {
                    return {
                        id: qsdoc.id,
                        ref: qsdoc.ref,
                        ...qsdoc.data()
                    }
                })
            ])
        }

        const unsubs: Unsubscribe[] = []
        dispatch([])

        for (const logo of logos) {
            unsubs.push(onSnapshot(query(
                collection(db, `/accounts/${accountId}/brands/${brandId}/assets/${logo.id}/files`), orderBy('updatedOn', 'desc'), limit(1)),
                updateImages
            ))
        }

        return unsubs
    }
}
