import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import React from "react"
import { useTranslation } from "react-i18next"
import { resetPassword } from "../../services/firebase"

type TAvatarMenu = {
    user: any
    accounts: string[] | null
    logout: Function
    setAccount: Function
    floating?: boolean,
    setSnackbar: Function
}

export function AvatarMenu({
    accounts,
    user,
    logout,
    setAccount,
    floating,
    setSnackbar
}: TAvatarMenu) {
    const { t } = useTranslation()
    const [anchorElUser, setAnchorElUser] = React.useState(null)
    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget)
    }
    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    function handleLogout() {
        logout()
    }
    async function handleResetPassword() {
        await resetPassword(user.email).then((result) => {
            setSnackbar({
                severity: "success",
                children: <Typography textAlign="center">{t("app-brand-reset-password-success")}</Typography>
            })
        }).catch((e) => {
            setSnackbar({
                severity: "error",
                children: <Typography textAlign="center">{t("app-brand-reset-password-error")}</Typography>
            })
        })
    }

    const extraSX = floating ? {
        position: "fixed",
        bottom: {
            xs: 16,
            sm: 24
        },
        left: {
            xs: 32,
            sm: 48
        }
    } : {}

    return (
        <Box sx={{ flexGrow: 0, ...extraSX }}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.name} src={user.photoURL} />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "center", horizontal: "left" }}
                keepMounted
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {accounts && (
                    <MenuItem key="switch" onClick={() => setAccount(null)}>
                        <Typography textAlign="center">Switch Account</Typography>
                    </MenuItem>
                )}

                {user?.providerData?.[0]?.providerId === "password" &&
                    <MenuItem key="resetPassword" onClick={handleResetPassword}>
                        <Typography textAlign="center">Reset password</Typography>
                    </MenuItem>
                }

                <MenuItem key="logout" onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}
