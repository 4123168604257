import styled from "@emotion/styled"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import Logo from "../../components/Logo/Logo"
import { ERoute, fetchRoute } from '../../utils/routes'
import { getFlowName } from "../../utils/utils"

export function Home() {
    const navigate = useNavigate()

    const LowButton = styled(Button)({
        textTransform: 'none'
    })
    const flowName = getFlowName()
    function handleSignUp() {
        navigate(fetchRoute(ERoute[flowName].signup))
    }

    function handleSignIn() {
        navigate(fetchRoute(ERoute[flowName].signin))
    }

    return <>
        <Container sx={{ marginTop: 3, minWidth: "100%" }}>
            <Box sx={{ display: 'flex', padding: "0  !important", alignItems: "center", justifyContent: "space-between" }}>
                <Logo />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: "0", width: "auto" }}>
                    <LowButton variant='contained' size='large' onClick={handleSignIn}>Login</LowButton>
                    <LowButton sx={{ marginLeft: 1 }} variant='contained' size='large' onClick={handleSignUp}>Sign Up for free</LowButton>
                </Box>
            </Box>
            <Grid container spacing={1} minHeight={500} maxHeight={800} alignContent='center' sx={{ display: "flex", verticalAlign: 'center', alignItems: "center" }}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='h2' sx={{ fontWeight: 'bold', paddingTop: 0.5 }}>Stay on-brand,<br />with BrandRepo.</Typography>
                    <Typography paddingTop={2} fontSize={18} variant='body1'>Give us your brand assets and instantly display them<br />everywhere your brand needs to be.</Typography>
                    <LowButton sx={{ marginTop: 5, padding: 2, paddingLeft: 4, paddingRight: 4 }} variant='contained' size='large' onClick={handleSignUp}>Sign Up for free</LowButton>
                </Grid>
            </Grid>
        </Container>
    </>
}