import { Box, Breadcrumbs, Grid, Link } from "@mui/material"
import { doc, DocumentReference, DocumentSnapshot, getDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import JSONPretty from "react-json-pretty"
import { useNavigate, useParams } from "react-router"
import { db } from "../../services/firebase"
import fetchRoute, { ERoute } from "../../utils/routes"
import { ITag } from "../../interfaces/ITag"

export function Tag({ account }: ITag) {
    const { id, tag } = useParams<string>()
    const [taggedBrand, setTaggedBrand] = useState<any>({})
    const navigate = useNavigate()

    if (!id || !tag) {
        throw new Error("no brand or tag id")
    }

    function getVersionByTagId() {
        const docRef = `/accounts/${account}/brands/${id}/tags/${tag}`
        getDoc(doc(db, docRef))
            .then(async (docSnap: DocumentSnapshot) => {
                const data = docSnap.exists() ? docSnap.data() : undefined
                const versionReference: DocumentReference = data ? data.ref : undefined
                if (!versionReference) throw new Error("Invalid Tag")
                return getDoc(versionReference)
            })
            .then((versionDocSnap: DocumentSnapshot) => {
                if (!versionDocSnap.exists()) throw new Error("Invalid Tag")
                setTaggedBrand(versionDocSnap.data())
            })
    }

    useEffect(getVersionByTagId, [])

    return <>
        <Grid container>
            <Grid item>
                <Box minWidth={500} sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 1, width: '100%' } }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component="button" variant="body2" underline="hover" color="inherit" onClick={() => { navigate(fetchRoute(ERoute.default.dashboard, { id: "unId" })) }}>
                            Brands
                        </Link>
                        {id &&
                            <Link component="button" variant="body2" underline="none" color="inherit" onClick={() => { navigate(fetchRoute(ERoute.default.brand, { id: id })) }}>
                                {id}
                            </Link>
                        }
                        {tag &&
                            <Link underline="none" color="inherit">
                                {tag}
                            </Link>
                        }
                    </Breadcrumbs>

                    {tag &&
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { paddingTop: 0, m: 0, width: '100%', minHeight: 100, color: '#ffffff', bgcolor: '#1e1e1e' } }}>
                            <JSONPretty data={taggedBrand} mainStyle="padding-left:1em" style={{ marginTop: '1rem', overflow: 'auto', maxHeight: '80vh' }} />
                        </Box>
                    }
                </Box>
            </Grid>
        </Grid>
    </>
}