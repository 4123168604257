import { Container } from '@mui/material'
import { useParams } from 'react-router'
import { AvatarMenu } from '../../components/AvatarMenu/AvatarMenu'
import { IPageLayoutProps } from '../../interfaces/IPageLayoutProps'
import { getFlowName } from '../../utils/utils'
import ResponsiveAppBar from '../ResponsiveAppBar/ResponsiveAppBar'
import AppLayout from './DefaultLayout'
import GuidedLayout from './GuidedLayout'
import PageHeader from './PageHeader'

export default function PageLayout({ pageTitle, children, rightComponent, account, isBrandHasMinReqToPublish, user, accounts, setSnackbar, setAccount, logout }: IPageLayoutProps) {
    const { id } = useParams<string>()

    const flowName = getFlowName()
    switch (flowName) {
        case "guided":
            return <>
                <Container sx={{ maxWidth: "unset !important", height: "-webkit-fill-available" }}>
                    <GuidedLayout children={children} pageTitle={pageTitle} account={account} isBrandHasMinReqToPublish={isBrandHasMinReqToPublish} />
                    <AvatarMenu
                        floating
                        accounts={accounts}
                        logout={logout}
                        setAccount={setAccount}
                        user={user}
                        setSnackbar={setSnackbar}
                    />
                </Container>
            </>
        default:
            return <>
                <ResponsiveAppBar user={user} account={account} accounts={accounts} snackbar={setSnackbar} setAccount={setAccount} logout={logout} />
                <Container sx={{ flexGrow: '1', padding: "0 !important", height: "calc(100vh)", overflowY: "scroll", maxWidth: "unset !important", display: "flex", flexDirection: "column" }}>
                    <PageHeader account={account} id={id || ""} snackbar={setSnackbar} />
                    <Container sx={{ maxWidth: "unset !important", height: "-webkit-fill-available", display: "flex", flexDirection: "column" }}>
                        <AppLayout children={children} pageTitle={pageTitle} rightComponent={rightComponent} />
                    </Container>
                </Container>
            </>
    }
}