import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { MouseEventHandler } from 'react'

type buttonProps = {
    label: string,
    onClick?: Function,
    loading?: boolean
}

export default function AlertDialog({ handleClose, open, title, content, primaryButtonProps, secondaryButtonProps }: { title: string, content: string, handleClose: MouseEventHandler<HTMLButtonElement>, primaryButtonProps?: buttonProps, open: boolean, secondaryButtonProps?: buttonProps }) {
    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            fullWidth
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            {(secondaryButtonProps || primaryButtonProps) &&
                <DialogActions>
                    {secondaryButtonProps &&
                        <Button
                            variant="outlined"
                            onClick={(event) => {
                                if (secondaryButtonProps.onClick) {
                                    secondaryButtonProps.onClick()
                                }
                                handleClose(event)
                            }}
                        >
                            {secondaryButtonProps.label}
                        </Button>
                    }
                    {primaryButtonProps &&
                        <LoadingButton
                            data-testid='loading-button'
                            loading={primaryButtonProps.loading}
                            loadingPosition="start"
                            variant="contained"
                            color='primary'
                            startIcon={primaryButtonProps.loading ? <SaveIcon /> : ""}
                            onClick={() => { if (primaryButtonProps.onClick) primaryButtonProps.onClick() }} autoFocus
                        >
                            {primaryButtonProps.label}
                        </LoadingButton>
                    }
                </DialogActions>
            }
        </Dialog>
    </>
}