import { createTheme, Snackbar, ThemeProvider } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { logEvent } from 'firebase/analytics'
import { IdTokenResult } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import { addDoc, collection, doc, DocumentReference, setDoc, updateDoc } from "firebase/firestore"
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import { PageLoader } from '../../components/PageLoader/PageLoader'
import { IAppContext } from "../../interfaces/IAppContext"
import { analytics, db, firebaseAuth, FirebaseContext } from "../../services/firebase"
import { Account } from '../../types/Account'
import { TFirebaseUser } from '../../types/TFirebaseUser'
import { ERoute, fetchRoute } from '../../utils/routes'
import { getFlowName } from '../../utils/utils'
import AccountSelection from '../AccountSelection/AccountSelection'
import AppRoutes from '../AppFlow/AppRoutes'
import PublicAppRoutes from '../AppFlow/PublicAppRoutes'
import GuidedRoutes from '../GuidedFlow/GuidedRoutes'
import PublicGuidedRoutes from '../GuidedFlow/PublicGuidedRoutes'
import './App.css'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

declare module '@mui/material/styles' {

    interface PaletteOptions {
        neutralDark?: PaletteOptions['primary'],
        neutralLight?: PaletteOptions['primary']
    }
}

declare module '@mui/material/Checkbox' {
    interface CheckboxPropsColorOverrides {
        neutralDark: true,
        neutralLight: true
    }
}

const theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(',')
    },
    palette: {
        primary: {
            // changing the main color to cakemail grape
            main: "#69006E"
        },
        secondary: {
            main: '#cdcbd1'
        },
        neutralDark: {
            main: '#FFFFFF'
        },
        neutralLight: {
            main: '#000000'
        }
    }

})

export function App() {
    const [urlParams, setUrlParams] = useSearchParams()
    const [confirmUser, setConfirmUser] = useState<boolean>(false)
    const [user, setUser] = useState<TFirebaseUser>(null)
    const [account, setAccount] = useState<string | null>(null)
    const [accounts, setAccounts] = useState<Account[] | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [creatingBrand, setCreatingBrand] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null)
    const [brandName, setBrandName] = useState("")
    const { api } = useContext<IAppContext>(FirebaseContext)
    const brands = useSelector((state: any) => state.brands.brands)
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const { t } = useTranslation()
    const unsubscribeGetBrands = useRef({})

    function handleCloseSnackbar() { setSnackbar(null) }

    /**
     * Takes care of logging in the user
     * - login through normal means
     * - login through confirm_email (refreshing token)
     */
    function login() {
        firebase.auth().onAuthStateChanged(setAccountOnLogin)

        if (urlParams.get('confirm_email')) {
            urlParams.delete('confirm_email')
            if (!user && firebaseAuth.currentUser) {
                firebaseAuth
                    .currentUser
                    .getIdToken(true)
                    .then(() => {
                        setUser(firebaseAuth.currentUser)
                        setConfirmUser(true)
                    })
            }
        }

        async function setAccountOnLogin(tokenUser: TFirebaseUser | null) {
            const flowName = getFlowName()
            if (tokenUser === null) {
                reset()
                setLoading(false)
                if (!window.location.pathname.includes("auth")) {
                    navigate(fetchRoute(ERoute[flowName].home))
                }
            } else {
                return tokenUser
                    .getIdTokenResult()
                    .then((idToken: firebase.auth.IdTokenResult) => {
                        const userAccounts = idToken.claims.accounts
                        setUser(tokenUser)
                    })
            }
        }
    }

    /**
     * Select the account and accounts from custom claims
     */
    function selectAccount() {
        if (!user || account) return

        user.getIdTokenResult().then((idToken: IdTokenResult) => {
            const userAccounts = idToken.claims.accounts
            setAccounts(userAccounts.length > 1 ? userAccounts : null)
            setAccount(userAccounts.length > 0 ? userAccounts.pop(0).id : null)
        })
    }

    function isInitialLoadingCompleted() {
        return account && user
    }

    /**
     * Create a brand - there must be one.
     */
    async function createBrand() {
        const newBrand = {
            metadata: {
                name: brandName !== "" ? brandName : "default brand"
            }
        }
        return addDoc(collection(db, `/accounts/${account}/brands`), newBrand)
            .then((addedDoc: DocumentReference) => {
                logEvent(analytics, 'brand_add', {
                    account_id: account,
                    brand_id: addedDoc.id,
                    brand_name: newBrand.metadata.name
                })
            })
    }

    function confirmUserFlag() {
        if (account && user && confirmUser) {
            setConfirmUser(false)
            setDoc(doc(db, `accounts/${account}/users/${user.uid}`), { confirmed: true }, { merge: true })
        }
    }

    /**
     * Registers the brands onSnapshot and creates a brand if there si none
     * @returns 
     */
    function checkBrand() {
        if (!user || !account) return
        if (typeof unsubscribeGetBrands?.current === "function") return

        unsubscribeGetBrands.current = api.getBrands(account, onSuccess, onFailure)

        function onSuccess(data: any) {
            if (data.length === 0) {
                if (!creatingBrand) {
                    setCreatingBrand(true)
                    createBrand()
                        .then(() => {
                            setCreatingBrand(false)
                        })
                        .finally(() => {
                            if (isInitialLoadingCompleted() && loading) {
                                setLoading(false)
                            }
                        })
                }
            } else {
                if (isInitialLoadingCompleted() && loading) {
                    setLoading(false)
                }
            }
        }

        function onFailure(e: any) {
            if (typeof unsubscribeGetBrands?.current === "function") {
                unsubscribeGetBrands.current()
                unsubscribeGetBrands.current = {}
            }
            setLoading(false)
        }
    }

    /**
     * Reset the app state and logout of firebase auth
     */
    function logout() {
        reset()
        firebaseAuth.signOut()
    }

    /**
     * Reset the app state
     */
    function reset() {
        if (typeof unsubscribeGetBrands?.current === "function") unsubscribeGetBrands.current()
        unsubscribeGetBrands.current = {}
        dispatch({ type: 'reset' })
        setUser(null)
        setAccount(null)
        setAccounts(null)
    }

    function oneBrand() {
        if (brands && brands[0]?.id) setLoading(false)
    }

    useEffect(login, [])
    useEffect(selectAccount, [user])
    useEffect(checkBrand, [account, user])
    useEffect(confirmUserFlag, [account, user])
    useEffect(oneBrand, [brands])

    return <>
        <ThemeProvider theme={theme}>
            {(user && account && user.emailVerified) ?
                <>
                    {
                        accounts ?
                            <AccountSelection account={account} accounts={accounts} setAccount={setAccount}></AccountSelection>
                            : brands[0]?.id &&
                            <Routes>
                                <Route path="/guided/*" element={<GuidedRoutes
                                    account={account}
                                    brands={brands}
                                    user={user}
                                    setSnackbar={setSnackbar}
                                    setAccount={setAccount}
                                    accounts={accounts || []}
                                    logout={logout}
                                />} />
                                <Route path="/*" element={<AppRoutes
                                    setAccount={setAccount}
                                    accounts={accounts}
                                    logout={logout}
                                    account={account}
                                    user={user}
                                    brands={brands}
                                    setBrandName={setBrandName}
                                    brandName={brandName}
                                    setSnackbar={setSnackbar}
                                />} />
                            </Routes>
                    }
                    {!!snackbar && (
                        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000} >
                            <Alert {...snackbar} onClose={handleCloseSnackbar} />
                        </Snackbar>
                    )}
                </>
                :
                <Routes>
                    <Route path="/guided/*" element={<PublicGuidedRoutes user={user} setBrandName={setBrandName} brandName={brandName} />} />
                    <Route path="/*" element={<PublicAppRoutes user={user} setBrandName={setBrandName} brandName={brandName} />} />
                </Routes>
            }

            {(user && !account && accounts) &&
                <AccountSelection account={account} accounts={accounts} setAccount={setAccount}></AccountSelection>
            }
            {loading &&
                <PageLoader />
            }
        </ThemeProvider >
    </>
}

export default App