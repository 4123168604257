import { Box, Button, Divider, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { IGuidedAssetLayout } from "../../interfaces/IGuidedAssetLayout"
import { translations } from "../../utils/Translations"
import AssetLayoutContent from "./AssetLayoutContent"

const GuidedAssetLayout = ({ backFunction, addButtonProps, inputProps, children, assetType }: IGuidedAssetLayout) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    function handleClick() {
        if (backFunction) return backFunction()
        return navigate(-1)
    }

    return <>
        <Box sx={{ height: "-webkit-fill-available", display: "flex", flexDirection: "column" }}>
            <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography variant="h4" sx={{ fontSize: "1.375rem" }}>{t(`app-brandassets_manage_${assetType?.toLowerCase()}`)}</Typography>
                <Button variant="contained" onClick={handleClick} color="secondary">{t(translations.DONE)}</Button>
            </Box>
            <Divider />
            <AssetLayoutContent addButtonProps={addButtonProps} inputProps={inputProps} showBackButton={false} >
                {children}
            </AssetLayoutContent>
        </Box>
    </>
}

export default GuidedAssetLayout