import React, { MouseEventHandler, useState } from 'react'
import { Button, Menu, MenuItem, ButtonProps } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

interface IbtnProps extends ButtonProps {
    label: string,
    isLoadingBtn?: boolean,
    loading?: boolean
}

export default function MenuButton(props: { btnProps: IbtnProps, menuOptions: { label: string, onClick: MouseEventHandler }[] }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        if (props.btnProps.onClick)
            props.btnProps.onClick(event)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    function menuClick(e: any, optionOnClick: MouseEventHandler) {
        handleClose()
        optionOnClick(e)
    }

    return (
        <div>
            {props.btnProps.isLoadingBtn ? <LoadingButton
                loading={props.btnProps.loading}
                loadingPosition="start"
                variant="contained"
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                {...props.btnProps}
                onClick={handleClick}
            >   {props.btnProps.label}</LoadingButton> :
                <Button
                    variant="contained"
                    aria-controls={open ? 'menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    {...props.btnProps}
                    onClick={handleClick}

                >
                    {props.btnProps.label}
                </Button>}
            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'button'
                }}
            >
                {
                    props.menuOptions.map((option => <MenuItem onClick={(e: any) => menuClick(e, option.onClick)}>{option.label}</MenuItem>))
                }

            </Menu>
        </div>
    )
}