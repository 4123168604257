import { CheckCircle } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { ILogoExtensionView } from "../../../interfaces/ILogoExtensionView"

const LogoExtensionView = ({ key, added, extension }: ILogoExtensionView) => {
    return <>
        <Typography key={key} variant='body1' sx={{ mr: 2, display: "flex" }}><CheckCircle color={added ? "success" : "disabled"} />{extension.toUpperCase()}</Typography>
    </>
}

export default LogoExtensionView