export const hexToRgb = (hex: string): string | null => {
    if (isValidHex(hex)) {
        // matching 8-digit/ 16-digit hex
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex)
        if (result) {
            return result ?
                `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})`.replace(/ /g, '')
                : null
        }
        // matching 4-digit hex
        const result2 = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])$/i.exec(hex)
        return result2 ?
            `rgb(${parseInt(result2[1], 16)},
            ${parseInt(result2[2], 16)},
            ${parseInt(result2[3], 16)})`.replace(/ /g, '')
            : null
    }
    return ""
}

export const rgbToHex = ({ r, g, b }: { r: number, g: number, b: number }): string => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}
export const isHexColor = (color: string) => {
    return color.startsWith("#")
}
export const isValidHex = (hex: string): boolean => {
    return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.test(hex) ||
        /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])$/i.test(hex)
}


export const rgbStringToObject = (rgb: string): { r: number, g: number, b: number } | null => {
    const result = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/.exec(rgb)
    return result ? {
        r: parseInt(result[1], 10),
        g: parseInt(result[2], 10),
        b: parseInt(result[3], 10)
    } : null
}
export const addRGB = (rgb: string): string => {
    let input = rgb
    let match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    if (match) {
        const r = parseInt(match[1], 10)
        const g = parseInt(match[2], 10)
        const b = parseInt(match[3], 10)
        if (r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255) {
            input = `rgb(${r}, ${g}, ${b})`
        }
    }
    else {
        match = input.match(/^(\d+),\s*(\d+),\s*(\d+)$/)
        if (match) {
            const r = parseInt(match[1], 10)
            const g = parseInt(match[2], 10)
            const b = parseInt(match[3], 10)
            if (r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255) {
                input = `rgb(${r}, ${g}, ${b})`
            }
        }
    }
    return input
}
export const addHash = (hex: string): string => {
    if (hex.startsWith('#')) {
        return hex
    }
    return '#' + hex
}