import { Delete, Download } from "@mui/icons-material"
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { doc, DocumentSnapshot, onSnapshot } from "firebase/firestore"
import { getDownloadURL, ref } from "firebase/storage"
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from 'react-router'
import AlertDialog from "../../../components/AlertDialog/AlertDialog"
import AssetLayout from '../../../components/AssetLayouts/AssetLayout'
import FileUploader from '../../../components/FileUploader/FileUploader'
import MakePrimaryCheckbox from "../../../components/MakePrimaryCheckbox/MakePrimaryCheckbox"
import SelectionSnackbar from "../../../components/Snackbars/SelectionSnackbar"
import { IAppContext } from "../../../interfaces/IAppContext"
import { ISingleLogosListing } from "../../../interfaces/ISingleLogosListing"
import { Images } from "../../../models/Images"
import { Logo } from "../../../models/Logo"
import { Logos } from '../../../models/Logos'
import { db, FirebaseContext, storage } from "../../../services/firebase"
import fetchRoute, { ERoute } from "../../../utils/routes"
import { translations } from "../../../utils/Translations"
import { formatBytes, getExtension, getFlowName } from '../../../utils/utils'
import { allowedExtensions } from "./consts"
import DarkmodeSwitch from "./DarkModeSwitch"
import LogoCard from './LogoCard'
import LogoExtensionView from "./LogoExtensionView"

const SingleLogosListing = ({ account, user, accounts, setSnackbar, setAccount, logout }: ISingleLogosListing) => {
    const { logoId } = useParams<string>()
    const [view, setView] = useState<"listing" | "uploader">("listing")
    const [logo, setLogo] = useState<Logo>()
    const [images, setImages] = useState<any[]>()
    const [newName, setNewName] = useState<string>()
    const [markAsPrimary, setMarkAsPrimary] = useState<boolean>(false)
    const [isPrimary, setIsPrimary] = useState<boolean>(false)
    const [selectedImages, setSelectedImages] = useState<string[]>([])
    const listOfLogoFilesTypes = images ? images.map((image: any) => { return getExtension(image.name).toLowerCase() }) : []
    const [openDeleteDlg, setOpenDeleteDlg] = useState<string | null>(null)
    const { t } = useTranslation()
    const { ui } = useContext<IAppContext>(FirebaseContext)
    const navigate = useNavigate()
    const theme = useTheme()
    const { id: brandId } = useParams<string>()

    if (!brandId) throw new Error("error")

    function getImages() {
        if (account && brandId && logoId) {
            return Images.list({ accountId: account, brandId: brandId, logoId: logoId, dispatch: setImages })
        }
    }

    function getLogo() {
        let unsub: any = null
        if (account && brandId && logoId) {
            const updateProps = (qsdoc: DocumentSnapshot) => {
                const data = qsdoc.data() || {}
                setLogo(new Logo({
                    id: qsdoc.ref.id,
                    ref: qsdoc.ref,
                    name: data.name,
                    nameOverride: data.nameOverride,
                    darkmode: data.darkmode
                }))
            }
            /**
             * TODO: this is sub-optimal cost-wise and should be moved to a model where
             *       the logo is provided to the component from the listing; since we had
             *       it loaded a few seconds ago (in the listing) why are we reloading it?
             */

            Logos.getWithTag({ accountId: account, brandId: brandId, logoId: logoId })
                .then((l: Logo) => {
                    setLogo(l)
                    setIsPrimary(l.tag === 'Primary')
                })
            unsub = onSnapshot(doc(db, `/accounts/${account}/brands/${brandId}/assets/${logoId}`), updateProps)
        }
        return () => { if (unsub) unsub() }
    }

    /**
      * Get a SignedURL from Storage and downloads it
      * 
      * @param image image document to download
      * @returns 
      */
    async function handleDownload(image: any) {
        return getDownloadURL(ref(storage, image.gsReference))
            .then(async (downloadUrl: string) => {
                return fetch(downloadUrl)
            })
            .then((response: Response) => {
                return response.blob()
            })
            .then((blob: Blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = image.name
                a.click()
            })
    }

    function handleImageClick(id: string, isToggle: boolean) {
        if (selectedImages.length > 0 || isToggle) {
            if (selectedImages.filter(img => img === id).length > 0) {
                setSelectedImages(selectedImages.filter(img => img !== id))
            } else {
                setSelectedImages([...selectedImages, id])
            }
        }
    }

    function deleteSelectionsHandler() {
        if (logoId && brandId) {
            selectedImages.forEach(img => {
                Images.delete({ accountId: account, brandId: brandId, logoId: logoId, imageId: img })
                    .then(() => {
                        // Track changes
                        ui.setAssetsChangeTracker(Date.now())
                        setSelectedImages((updatedImages) => updatedImages.filter(i => i !== img))
                    }
                    )
            })
        }
    }

    function deleteSingleImage(image: any) {
        if (logoId && brandId) {
            Images.delete({ accountId: account, brandId: brandId, logoId: logoId, imageId: image.id })
                .then(() => {
                    // Track changes
                    ui.setAssetsChangeTracker(Date.now())
                    setOpenDeleteDlg(null)
                })
        }
    }

    function handleImageDelete(image: string) {
        setOpenDeleteDlg(image)
    }

    function setMarkAsPrimaryAndCloseDialog() {
        setMarkAsPrimary(true)
    }

    /** returns wXh px sizeBytes
     * for svgs returns wXh px
     */
    const getAdditionalFooterInfo = (image: any) => {
        if (getExtension(image.name) === "SVG") {
            return `${formatBytes(image.size)}`
        }
        return `${image.dimensions.width}X${image.dimensions.height} px  ${formatBytes(image.size)}`
    }
    const flowName = getFlowName()

    async function renameLogo(e: any) {
        if (logo) {
            setNewName(e.target.value)
            logo.nameOverride = e.target.value
        }
    }

    useEffect(getImages, [account, brandId, logoId])
    useEffect(getLogo, [account, brandId])
    useEffect(() => {
        if (images && images?.length === 0 && view !== "uploader") {
            setView("uploader")
        }
    }, [images])

    return <>
        {logo &&
            <AssetLayout
                assetType='Logo'
                addButtonProps={[
                    {
                        children: <MakePrimaryCheckbox isPrimary={isPrimary || markAsPrimary} />,
                        disableFocusRipple: isPrimary || markAsPrimary,
                        disableRipple: isPrimary || markAsPrimary,
                        disableElevation: isPrimary || markAsPrimary,
                        sx: {
                            cursor: isPrimary || markAsPrimary ? 'default' : 'pointer',
                            "&:hover": { bgcolor: theme.palette.primary.main }
                        },
                        onClick: () => {
                            if (!isPrimary && !markAsPrimary) {
                                setMarkAsPrimaryAndCloseDialog()
                            }
                        }
                    },
                    { children: <span>{t("app-brandassets_logos_images-uploadBtn-lbl")}</span>, onClick: () => { setView("uploader") } }
                ]}
                backFunction={() => {
                    if (markAsPrimary) {
                        Logos.tag({ accountId: account, brandId: brandId, logo: logo, as: 'Primary' })
                    }
                    if (newName !== undefined && newName !== "") {
                        logo.rename(newName)
                    }
                    // TODO : as a quick fix, we replaced navigate(-1) to the actual route, this is not optimal
                    // it works now since the only back state is from the brand assets
                    navigate(fetchRoute(ERoute[flowName].brandAssets, { id: brandId, tab: 0 }))
                }}
                inputProps={{
                    onChange: renameLogo,
                    value: newName !== undefined ? newName : logo.nameOverride !== undefined ? logo.nameOverride : logo.name,
                    id: "name",
                    placeholder: t(translations.UNTITLED_LOGO) as string
                }}
                account={account}
                setSnackbar={setSnackbar}
                setAccount={setAccount}
                user={user}
                accounts={accounts}
                logout={logout}
            >
                {view === "listing" ? images ? images.length > 0 ?
                    <>
                        <Box sx={{ display: "flex", mb: 2 }}>
                            {
                                allowedExtensions.map((ext: string, index: number) => <LogoExtensionView key={`logo_${ext}_${index}`} extension={ext} added={listOfLogoFilesTypes.includes(ext)} />)
                            }
                        </Box>
                        <DarkmodeSwitch logo={logo} isDarkMode={logo.darkmode} />
                        <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(25rem, 1fr))" gap={1}>
                            {images.map((image: any) => {
                                return <>
                                    <LogoCard
                                        isDarkMode={logo.darkmode}
                                        allowSelection={true}
                                        toggleSelected={() => handleImageClick(image.id, true)}
                                        selectionMode={selectedImages.length > 0}
                                        selected={selectedImages.filter(img => img === image.id).length > 0}
                                        onClick={() => handleImageClick(image.id, false)}
                                        logo={{ additionalFooterText: getAdditionalFooterInfo(image), name: getExtension(image.name), object: getExtension(image.name), url: image.downloadUrl, id: logoId }}
                                        menuActions={[{
                                            icon: <Download />,
                                            onClick: () => handleDownload(image)
                                        },
                                        {
                                            icon: <Delete />,
                                            onClick: () => handleImageDelete(image)
                                        }
                                        ]}
                                    />
                                </>
                            })}
                        </Box>
                    </>
                    : <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }} ></Box>
                    : <div>loading</div>
                    : logo &&
                    <>
                        <Box sx={{ display: "flex", mb: 2 }}>
                            {
                                allowedExtensions.map((ext: string, index: number) => <LogoExtensionView key={`logo_${ext}_${index}`} extension={ext} added={listOfLogoFilesTypes.includes(ext)} />)
                            }
                        </Box>
                        <FileUploader
                            showCloseBtn={(images && images?.length > 0) ? true : false}
                            handleClose={() => { if (view) setView("listing") }}
                            currentExtensions={images ? images.map(i => getExtension(i.name)) : []}
                            account={account}
                            brandId={brandId}
                            logoName={logo.name}
                            onStart={() => {/**/ }}
                            onSuccess={() => { if (view) setView("listing") }}
                        />
                    </>
                }
                <SelectionSnackbar numberOfSelectedElements={selectedImages.length}
                    actions={[{ label: t(translations.DELETE), onClick: deleteSelectionsHandler }]}
                />
                {
                    <AlertDialog
                        title={t(translations.COMFIRM_DELETE, { data: " logo?" })}
                        primaryButtonProps={{ label: "yes", onClick: () => openDeleteDlg && deleteSingleImage(openDeleteDlg) }}
                        secondaryButtonProps={{
                            label: t(translations.CANCEL),
                            onClick: () => { setOpenDeleteDlg(null) }
                        }} content={''}
                        open={openDeleteDlg !== null}
                        handleClose={() => { setOpenDeleteDlg(null) }}
                    />
                }
            </AssetLayout>
        }
    </>
}

export default SingleLogosListing