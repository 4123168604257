import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import MenuButton from '../../components/MenuButton/MenuButton'
import { IBasicMenu } from '../../interfaces/IBasicMenu'
import { translations } from '../../utils/Translations'

export default function BasicMenu({ handleAddColor, handleAddLogo }: IBasicMenu) {
    const { t } = useTranslation()

    return <>
        <MenuButton
            btnProps={{
                variant: "contained",
                startIcon: <Add />,
                label: t(translations.NEW_ASSET)
            }}
            menuOptions={[
                {
                    label: t(translations.ADD_LOGO),
                    onClick: handleAddLogo
                },
                {
                    label: t(translations.ADD_COLOUR),
                    onClick: handleAddColor
                }
            ]}
        />
    </>
}