import { Add } from '@mui/icons-material'
import { Box, Typography } from "@mui/material"
import { MouseEventHandler } from "react"
import AssetCard from "../AssetCard/AssetCard"

const AddCard = (props: { title: string, description: string, onClick: MouseEventHandler, sx?: any }) => {
    return <>
        <AssetCard
            bgColor="#000000"
            sx={{ bgcolor: "primary.main", ...props.sx }}
            onCardClick={props.onClick}
        >
            <Box

                sx={{ display: "flex", p: 2, textAlign: "center", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white !important" }}>
                <Add sx={{ m: 2 }} />
                <Typography sx={{ mb: 0.5 }} variant="h6"><b>{props.title}</b></Typography>
                <Typography variant="subtitle2">{props.description}</Typography>
            </Box>
        </AssetCard>
    </>
}

export default AddCard