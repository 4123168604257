import React, { MouseEventHandler, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { getExtension } from '../../utils/utils'

export default function ReplaceFileDialog({ handleReject, handleAccept, fileName, open }: { handleReject: MouseEventHandler, handleAccept: MouseEventHandler, fileName: string, open: boolean }) {



    return (<Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle >
            A {getExtension(fileName)} File already exist
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                You already have a {getExtension(fileName)} file exist, would you like to replace it?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleReject}>No</Button>
            <Button onClick={handleAccept} autoFocus>
                Yes
            </Button>
        </DialogActions>
    </Dialog>

    )
}