import { getAnalytics } from "firebase/analytics"
import { getApp } from 'firebase/app'
import { connectAuthEmulator } from "firebase/auth"
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from "firebase/storage"
import React from 'react'
import { useDispatch } from 'react-redux'
import { getBrand, getBrands, listTags, updateBrand } from "../actions"
import { setAssetsChangeTracker, setPublishedOn } from "../UiActions"
import { IAppContext } from "../interfaces/IAppContext"

const stagingConfig = {
    apiKey: "AIzaSyD29u8VmlM6Yfb_iCbth303_CNUqbuRv4s",
    authDomain: "brandsdk-stg.web.app",
    projectId: "brandsdk-stg",
    storageBucket: "brandsdk-stg.appspot.com",
    messagingSenderId: "1076500979821",
    appId: "1:1076500979821:web:f542913be650c8d4179f64",
    measurementId: "G-7DQH79TGP2"
}

const prodConfig = {
    apiKey: "AIzaSyBfPI0nN_OXcPnHXqBxVt8Rkk5Ed_XlWOI",
    authDomain: "brandrepo.com",
    projectId: "brand-repo",
    storageBucket: "brand-repo.appspot.com",
    messagingSenderId: "891356023847",
    appId: "1:891356023847:web:d690c81efa6a26867a9202",
    measurementId: "G-4K158H0W8D"
}

export { firebaseAuth, db, storage }

type Props = {
    children: JSX.Element,
}

switch (window.location.hostname) {
    case 'brandrepo.com':
    case 'brandrepo.web.app':
        firebase.initializeApp(prodConfig)
        break
    default:
        firebase.initializeApp(stagingConfig)
        break
}

const app = getApp()
const functions = getFunctions(app, 'northamerica-northeast1')
const db: any = getFirestore(app)
export const analytics = getAnalytics(app)

const firebaseAuth = firebase.auth()
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
const storage = getStorage(app)

// hook emulators
if (window.location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, "localhost", 5006)
    //connectFirestoreEmulator(db, "localhost", 8080)
    //connectAuthEmulator(firebaseAuth, 'http://localhost:9099')
    //connectStorageEmulator(storage, 'localhost', 9199)
}

export async function loginWithGoogle() {
    return firebaseAuth.signInWithPopup(googleAuthProvider)
}

export const tagBrand = httpsCallable(functions, 'tagBrand')
export const recursiveDeleteAsset = httpsCallable(functions, 'recursiveDeleteAsset')

export async function loginWithEmailAndPassword(email: string, password: string) {
    return firebaseAuth.signInWithEmailAndPassword(email, password)
        .then((user: firebase.auth.UserCredential) => {
            if (!user.user?.emailVerified) throw new Error("Email not verified")
        })
}

export async function resetPassword(email: string) {
    return firebaseAuth
        .sendPasswordResetEmail(email)
        .catch(() => { return })
}

export async function signUpWithEmailAndPassword(email: string, password: string) {
    return firebaseAuth.createUserWithEmailAndPassword(email, password)
        .then((user: firebase.auth.UserCredential) => {
            let url = window.location.href
            if (window.location.search !== "") url = `${url}&confirm_email=true`
            else url = `${url}?confirm_email=true`
            return user.user?.sendEmailVerification({ url: url })
        })
}

/**
 * REACT Context
 */
export const FirebaseContext = React.createContext<IAppContext | any>({})

export default ({ children }: Props) => {
    const dispatch = useDispatch()

    const contextValue: IAppContext = {
        ui: {
            setPublishedOn: (payload: any) => setPublishedOn(payload, dispatch),
            setAssetsChangeTracker: (payload: any) => setAssetsChangeTracker(payload, dispatch)
        },
        app: app,
        database: db,
        api: {
            getBrands: (account: any, successCallback: Function, failureCallback: any) => getBrands(account, successCallback, failureCallback, dispatch),
            getBrand: (account: any, id: string) => getBrand(account, id, dispatch),
            listTags: (account: any, brandId: string, successCallback?: Function) => listTags(account, brandId, dispatch, successCallback),
            updateBrand: (account: any, id: string, updatedBrand: any) => updateBrand(account, id, updatedBrand, dispatch)
        }
    }

    return (
        <FirebaseContext.Provider value={contextValue}>
            {children}
        </FirebaseContext.Provider>
    )
}

