import { collection, deleteDoc, doc, DocumentSnapshot, getDoc, getDocs, onSnapshot, orderBy, query, QuerySnapshot, runTransaction, setDoc, Unsubscribe } from "firebase/firestore"
import { ILogos } from "../interfaces/ILogos"
import { ILogosList } from "../interfaces/ILogosList"
import { analytics, db } from "../services/firebase"
import { ILogosGetById } from "../interfaces/ILogosGetById"
import { Logo } from "./Logo"
import { sha256 } from 'js-sha256'
import { logEvent } from "firebase/analytics"

export class Logos {
    static async get({ accountId, brandId, logoId }: ILogosGetById): Promise<Logo> {
        return getDoc(doc(db, `/accounts/${accountId}/brands/${brandId}/assets/${logoId}`))
            .then((sourceDoc: DocumentSnapshot) => {
                if (!sourceDoc.exists) {
                    throw new Error("Logo does not exist")
                }
                const data: any = sourceDoc.data()
                return new Logo({
                    id: sourceDoc.ref.id,
                    ref: sourceDoc.ref,
                    name: data.name,
                    nameOverride: data.nameOverride,
                    darkmode: data.darkmode
                })
            })
    }

    static async create({ accountId, brandId, logoName }: { accountId: string, brandId: string, logoName: string }): Promise<Logo> {
        const h = hash(logoName)
        return setDoc(doc(db, `accounts/${accountId}/brands/${brandId}/assets/${h}`), { name: logoName }, { merge: true })
            .then(() => {
                logEvent(analytics, 'logo_create', {
                    account_id: accountId,
                    brand_id: brandId,
                    logo_id: h,
                    logo_name: logoName
                })
                return Logos.get({ accountId: accountId, brandId: brandId, logoId: h })
            })
    }

    /**
     * Get a Logo including its tag
     * 
     * @remark
     * This requires listing all logos first, because tags are derived from the listing and are
     * not actually stored in the logo.
     * 
     * Cost-wise, this is suboptimal.
     * 
     * @returns Logo
     */
    static async getWithTag({ accountId, brandId, logoId }: ILogosGetById): Promise<Logo> {
        return Logos
            .getList({ accountId: accountId, brandId: brandId })
            .then((logos: Logo[]) => {
                const logo = logos.filter((l: Logo) => l.id === logoId)
                if (logo.length === 0) throw new Error('Logo does not exist')

                return logo[0]
            })
    }

    /**
     * Modifies the list of Logos to include tags
     * 
     * @param {QuerySnapshot} querySnap querysnap of the list of logos (including tags)
     * @param {Function} dispatch dispatch callback, setting logos
     * @returns {Logo[]} tagged logos
     */
    private static compileTags(querySnap: QuerySnapshot, dispatch?: Function): Logo[] {
        const rLogos: Logo[] = []
        const logoTags: any[] = []
        /**
         * List all logos and tags separately.  Tags have a "ref" property
         */
        querySnap.forEach((qsdoc: DocumentSnapshot) => {
            const data: any = qsdoc.data()
            if (data.ref) {
                logoTags.push({
                    id: qsdoc.ref.id,
                    ref: data.ref
                })
            } else {
                rLogos.push(new Logo({
                    id: qsdoc.ref.id,
                    ref: qsdoc.ref,
                    name: data.name,
                    nameOverride: data.nameOverride,
                    darkmode: data.darkmode
                }))
            }
        })

        /**
         * Tag logos
         */
        for (const logoTag of logoTags) {
            const logo: any = rLogos.find(l => l.id === logoTag.ref)
            if (logo) { logo.tag = logoTag.id }
        }

        if (dispatch) dispatch(rLogos)

        return rLogos
    }

    /**
     * Returns all Logos of a brand
     * 
     * @returns {Logo[]} All logos of a brand
     */
    private static async getList({ accountId, brandId }: { accountId: string, brandId: string }): Promise<Logo[]> {
        return getDocs(
            query(
                collection(db, `/accounts/${accountId}/brands/${brandId}/assets`),
                orderBy('name', 'asc')
            ))
            .then((querySnap: QuerySnapshot) => {
                return this.compileTags(querySnap)
            })
    }

    static list({ accountId, brandId, dispatch }: ILogosList): Unsubscribe {
        return onSnapshot(query(
            collection(db, `/accounts/${accountId}/brands/${brandId}/assets`),
            orderBy('name', 'asc')
        ), (querySnap: QuerySnapshot) => this.compileTags(querySnap, dispatch))
    }

    static async delete({ accountId, brandId, logoId }: ILogos) {
        return Logos.get({ accountId: accountId, brandId: brandId, logoId: logoId })
            .then((logo: Logo) => {
                logEvent(analytics, 'logo_delete', {
                    account_id: accountId,
                    brand_id: brandId,
                    logo_id: logoId
                })
                return logo.delete()
            })
    }

    static async tag({ accountId, brandId, logo, as }: { accountId: string, brandId: string, logo: Logo, as: string }) {
        const newTag = {
            name: as,
            ref: logo.id,
            createdOn: new Date()
        }
        const tagDocument = doc(db, `/accounts/${accountId}/brands/${brandId}/assets/${as}`)

        return deleteDoc(tagDocument).then(() => {
            return setDoc(tagDocument, newTag)
                .then(() => {
                    logEvent(analytics, 'logo_tag', {
                        account_id: accountId,
                        brand_id: brandId,
                        logo_id: logo.id,
                        logo_name: logo.name,
                        tag: as
                    })
                })
        })
    }

    static async forceTag({ accountId, brandId, logoName, as }: { accountId: string, brandId: string, logoName: string, as: string }) {
        const newTag = {
            name: as,
            ref: hash(logoName),
            createdOn: new Date()
        }
        const tagDocument = doc(db, `/accounts/${accountId}/brands/${brandId}/assets/${as}`)

        return deleteDoc(tagDocument).then(() => {
            return setDoc(tagDocument, newTag)
                .then(() => {
                    logEvent(analytics, 'logo_tag', {
                        account_id: accountId,
                        brand_id: brandId,
                        logo_id: hash(logoName),
                        logo_name: logoName,
                        tag: as
                    })
                })
        })
    }
}
export function hash(input: string): string {
    const h = sha256.create()
    h.update(input)
    return h.hex()
}