import { Add, Language } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import CopyButton from '../../components/CopyButton/CopyButton'
import { analytics, FirebaseContext, tagBrand } from "../../services/firebase"
import { IAppContext } from "../../interfaces/IAppContext"
import { TTag } from "../../types/TTag"
import PageLayout from "../Layouts/PageLayout"
import { CreateTagDialog } from './CreateTagDialog'
import { IBrand } from '../../interfaces/IBrand'
import { logEvent } from 'firebase/analytics'

export function Brand({ account, snackbar, user, accounts, setAccount, setSnackbar, logout }: IBrand) {
    const { id } = useParams<string>()
    const { api } = useContext<IAppContext>(FirebaseContext)
    const [showTagDialog, setShowTagDialog] = useState<boolean>(false)
    const unsubscribeListTags = useRef<any>({})
    const tags = useSelector((state: any) => state.tags.tags)
    const { t } = useTranslation()

    if (!id) {
        throw new Error("no brand id")
    }

    function listTags() {
        unsubscribeListTags.current = api.listTags(account, id)
    }

    function publish(tag: TTag) {
        tagBrand({ brandId: id, tagName: [tag.id], accountId: account })
            .then(() => {
                logEvent(analytics, 'brand_publish', {
                    account_id: account,
                    brand_id: id,
                    brand_tag: tag.id
                })
                snackbar({ children: `Brand successfully published to ${tag.id}`, severity: 'success' })
            })
            .catch((error: any) => {
                snackbar({ children: `Brand not published to ${tag.id}: ${t(error.message)}`, severity: 'error' })
            })
    }

    useEffect(listTags, [])

    return <>
        <PageLayout pageTitle="Destinations"
            account={account}
            rightComponent={
                <Button
                    variant="contained"
                    onClick={() => { setShowTagDialog(true) }}
                    startIcon={<Add />}>Add
                </Button>
            }
            setSnackbar={setSnackbar}
            setAccount={setAccount}
            user={user}
            accounts={accounts}
            logout={logout}
        >
            <Box minWidth={500} sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 1, width: '100%' } }}>
                <Typography variant="body1">Destinations</Typography>
                <Divider sx={{ borderBottomWidth: "3px" }} />
                {tags ?
                    <>
                        {tags.map((tag: TTag) =>
                            <>
                                <Box key={tag.id} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                        <Language sx={{ mr: 1 }} />
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography variant="body1" color={"primary"}><b>{tag.id}</b></Typography>
                                            <Typography variant="subtitle2">{getTimeDifference(tag.updatedOn.seconds * 1000)}</Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <CopyButton variant='contained' copiedText={tag.url} sx={{ mr: 2 }} >
                                            Copy url
                                        </CopyButton>
                                        <Button variant='contained' onClick={() => publish(tag)}>
                                            Publish
                                        </Button>
                                    </Box>
                                </Box>
                                <Divider sx={{ borderBottomWidth: "3px" }} />
                            </>
                        )}
                    </>
                    :
                    <CircularProgress sx={{ margin: "auto", display: "block", position: "absolute", left: "50vw", top: "50vh" }} color="primary" />
                }
            </Box>
            {showTagDialog &&
                <CreateTagDialog
                    account={account}
                    brandId={id}
                    onClose={() => setShowTagDialog(false)}
                    snackbar={snackbar}
                />
            }
        </PageLayout>
    </>
}

function getTimeDifference(date: any) {
    const prefix = <b>Published </b>
    const differenceInDays = (Math.abs(new Date().getTime() - date) / (1000 * 3600 * 24))

    if (differenceInDays > 1) {
        if (differenceInDays > 7) {
            return (<><b>Published on</b> {new Date(differenceInDays).toString()}</>)
        }
        return (<>{prefix} {Math.round(differenceInDays)}  days ago </>)
    }

    const differenceInHours = differenceInDays * 24
    if (differenceInHours > 1) {
        return (<>{prefix} {Math.round(differenceInHours)}  hours ago</>)
    }

    const differenceInMinutes = differenceInHours * 60
    if (differenceInMinutes > 1) {
        return (<>{prefix} {Math.round(differenceInMinutes)}  minutes ago</>)
    }

    return "Published few seconds ago"
}

export default Brand