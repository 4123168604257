import styled from "@emotion/styled"
import { Icon } from "@mui/material"

const StyledIconWithContrast = styled(Icon)((props: any) => {
    // Calculate the luminance of the background color
    // to determine if the text should be light or dark
    const luminance = props
        .bgColor
        .replace(/^#/, "")
        .match(/.{2}/g)
        .reduce((a: string, b: string) => a + parseInt(b, 16), 0)

    return {
        color: luminance > 382 ? "#000000" : "#ffffff",
        fontSize: "1.5rem"
    }
})

function IconWithContrast(props: any) {
    return <StyledIconWithContrast xs={{}} {...props} />
}

export default IconWithContrast
