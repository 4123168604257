import { Box, TextField } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import Logo from "../../components/Logo/Logo"
import { ISignUp } from "../../interfaces/ISignUp"
import { signUpWithEmailAndPassword } from "../../services/firebase"
import fetchRoute, { ERoute } from '../../utils/routes'
import { translations } from "../../utils/Translations"
import { getFlowName } from "../../utils/utils"
import { isValidString } from "../../utils/Validators"
import SignButtonGroup from "./SignButtonGroup"
import SignHeader from "./SignHeader"
import { ESignInUpError } from "../../types/ESignInUpError"
import { SignWithEmailForm } from "./SignWithEmailForm"

const SignUp = ({ setBrandName, brandName }: ISignUp) => {
    const [step, setStep] = useState("signupOptions")
    const [error, setError] = useState<ESignInUpError>()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const flowName = getFlowName()

    function handleSignUp(email: string, password: string) {
        signUpWithEmailAndPassword(email, password)
            .then(() => {
                navigate(fetchRoute(ERoute[flowName].verifyEmail))
            })
            .catch((ex: string) => {
                setError(ESignInUpError.EMAIL_ALREADY_IN_USE)
            })
    }

    return <>
        <Box sx={{ display: "flex", flexDirection: "column", p: 2, width: "100%" }}>
            <Logo />
            <Box sx={{ alignSelf: "center", maxWidth: "25rem", flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <SignHeader
                    navigateTo={ERoute[flowName].signin}
                    navigateToLabel={t(translations.SIGN_IN)}
                    subtitle={t(translations.CREATE_ACCOUNT)}
                />
                <TextField sx={{ width: "-webkit-fill-available", mt: 3 }} id="brandName" label={t(translations.ENTER_BRAND_NAME)} variant="outlined"
                    onChange={(e) => { setBrandName(e.target.value) }}
                    value={brandName} />
                {step === "signupOptions" ?
                    <>
                        <SignButtonGroup
                            signWithGoogleButtonProps={{ label: t(translations.SIGN_UP_GOOGLE), disabled: isValidString(brandName) ? false : true }}
                            signWithEmailButtonProps={{ label: t(translations.SIGN_UP_EMAIL), disabled: isValidString(brandName) ? false : true, onClick: () => { setStep("email") } }}
                        />
                    </> :
                    <>
                        <SignWithEmailForm
                            validatePassword={true}
                            setError={setError}
                            setStep={setStep}
                            error={error}
                            signButtonProps={{ label: t(translations.SIGN_UP), onClick: handleSignUp }}
                            footerLink={{
                                label: t(translations.SIGN_UP_DIFFERENTLY), onClick: () => {
                                    setStep("signupOptions")
                                }
                            }}
                        />
                    </>
                }
            </Box>
        </Box>
    </>
}

export default SignUp