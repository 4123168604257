import { Rocket } from '@mui/icons-material'
import { AppBar, Box, Toolbar } from '@mui/material'
import { useContext, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import MenuButton from "../../components/MenuButton/MenuButton"
import { PublishBrandDialog } from "../../components/PublishBrandDialog/PublishBrandDialog"
import SearchInput from "../../components/SearchInput/SearchInput"
import { IAppContext } from "../../interfaces/IAppContext"
import { IPageHeader } from '../../interfaces/IPageHeader'
import { FirebaseContext, tagBrand } from "../../services/firebase"
import { translations } from '../../utils/Translations'

const PageHeader = ({ account, id, snackbar }: IPageHeader) => {
    const [showTagDialog, setShowTagDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const { api } = useContext<IAppContext>(FirebaseContext)
    const tags = useSelector((state: any) => state.tags.tags)
    const { t } = useTranslation()

    function tagBrandAction(updatedTags: any[]) {
        tagBrand({ brandId: id, tagName: updatedTags.map((tag: any) => tag.id), accountId: account })
            .then(() => {
                setLoading(false)
                snackbar({ children: t(translations.SUCCESS_PUBLISH_TO_ALL), severity: 'success' })
            })
            .catch((error: any) => {
                setLoading(false)
                snackbar({ children: `${t(translations.FAILURE_PUBLISH_TO_ALL)} ${t(error.message)}`, severity: 'error' })
            })
    }

    async function publishToAll() {
        setLoading(true)
        if (!tags) {
            const unsubscribeListTags = api.listTags(account, id, (tagsData: any[]) => {
                tagBrandAction(tagsData)
                unsubscribeListTags()
            })
        } else {
            tagBrandAction(tags)
        }
    }

    return <>
        <AppBar position="static" sx={{ backgroundColor: "#F1F1F1", display: "flex", position: "sticky", top: "0", zIndex: "2", boxShadow: "none" }}>
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }} >
                <SearchInput />
                <Box sx={{ width: "fit-content", display: "flex" }}>
                    <MenuButton
                        btnProps={{
                            variant: "contained",
                            sx: { margin: "1rem" },
                            startIcon: <Rocket />,
                            label: t(translations.PUBLISH),
                            isLoadingBtn: true,
                            loading: loading
                        }}
                        menuOptions={[
                            {
                                label: t(translations.PUBLISH_TO_ALL),
                                onClick: publishToAll
                            },
                            {
                                label: t(translations.SPECIFIC),
                                onClick: () => {
                                    setShowTagDialog(true)
                                }
                            }
                        ]}
                    />
                </Box>
            </Toolbar>
            {showTagDialog &&
                <PublishBrandDialog account={account} brandId={id} snackbar={snackbar} onClose={() => { setShowTagDialog(false) }} />
            }
        </AppBar >
    </>
}

export default PageHeader