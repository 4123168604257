import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import { Button, Divider, FormControl, FormHelperText, InputLabel, OutlinedInput, Typography } from "@mui/material"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { TEmailState } from "../../types/TEmailState"
import { translations } from "../../utils/Translations"
import { ESignInUpError } from "../../types/ESignInUpError"
import { IResetPasswordForm } from "../../interfaces/IResetPasswordForm"
import { resetPassword } from '../../services/firebase'
import { validateEmail } from "../../utils/Validators"

export function ResetPasswordForm({ setError, signButtonProps, error, footerLink }: IResetPasswordForm) {
    const [email, setEmail] = useState<TEmailState>({ value: "" })

    function handleEmailChange(e: any) {
        const emailValue = e.target.value
        if (email.error) {
            setEmail({ ...validateEmail(emailValue), value: emailValue })
            return
        }
        setEmail({ ...email, value: emailValue })
    }

    function validateEmailHandler() {
        const updatedEmail = validateEmail(email.value)
        setEmail({ ...email, ...updatedEmail })
    }

    function handleResetPassword() {
        resetPassword(email.value)
        setError(ESignInUpError.EMAIL_MAYBE_RESET)
    }

    useEffect(() => { return (setError(null)) }, [])

    return <>
        {!error &&
            <FormControl error={email?.error} fullWidth sx={{ mt: 3 }} variant="outlined">
                <InputLabel htmlFor="email">{t(translations.EMAIL)}</InputLabel>
                <OutlinedInput
                    inputProps={{ shrink: true }}
                    fullWidth
                    value={email?.value ? email.value : ""}
                    onBlur={validateEmailHandler}
                    color={email?.error ? "error" : "primary"}
                    id="email" type="text" label={t(translations.EMAIL)} onChange={handleEmailChange} />
                <FormHelperText id="email-helper">{email?.message}</FormHelperText>
                <Button size="large" sx={{ width: "-webkit-fill-available", mt: 2, mb: 2 }} variant='contained' onClick={handleResetPassword}>
                    {signButtonProps.label}
                </Button>
            </FormControl>
        }
        {error &&
            <Typography ml={1} mt={2} variant="body1" color="info">
                {error === ESignInUpError.EMAIL_MAYBE_RESET && t(translations.EMAIL_MAYBE_RESET)}
            </Typography>
        }
        <Divider sx={{ marginTop: 8, marginBottom: 3 }} />
        <Button variant="text" onClick={footerLink.onClick} startIcon={<ArrowBackIos />} >
            {footerLink.label}
        </Button>
    </>
}